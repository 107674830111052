import React from 'react'
import SideMenu from '../../component/sidebar/SideMenu';
import TinyCard from '../../component/tiny-cards/TinyCard';
import ReactEcharts from "echarts-for-react"; 

function DashboardWrapper() {
 
  const option = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center',
      // doesn't perfectly work with our tricks, disable it
      selectedMode: false
    },
    series: [
      {
        name: 'Appointment',
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '70%'],
        // adjust the start angle
        startAngle: 180,
        label: {
          show: true,
          formatter(param) {
            // correct the percentage
            return param.name + ' (' + param.percent * 2 + '%)';
          }
        },
        data: [
          { value: 25, name: 'Booked' },
          { value: 10, name: 'Queued' },
          { value: 15, name: 'DOne' },
         
          {
            // make an record to fill the bottom 50%
            value: 25 + 10 + 15,
            itemStyle: {
              // stop the chart from rendering this piece
              color: 'none',
              decal: {
                symbol: 'none'
              }
            },
            label: {
              show: false
            }
          }
        ]
      }
    ]
  };
 
  return (
    <>
    
    
    <div className='p-4'>
    <div >
      <TinyCard/>
    </div>
       <div className='container'>
       <div className='row'>
       <div className='card'>
        <ReactEcharts option={option} />
        </div>
        </div>

       </div>
       </div>
      

   
    </>
  )
}

export default DashboardWrapper;