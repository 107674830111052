import React from 'react'
import { useState, useRef, useEffect } from 'react';
import PreviewModel from '../../component/modelPopup/PreviewModel';
import * as Icon from 'react-bootstrap-icons';
import QuestionCard from '../../component/questionDisplaycard/QuestionCard';
import Accordion from 'react-bootstrap/Accordion';
import * as service1 from './../../service/ApiService';
import * as service from './../../service/SurveyService';
import { useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from '../../Utils/MapConstant';
import * as popUp from '../../component/MessagePopup/MessagePopupComp';

function ManageQuestion() {
  const [show, setShow] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [reload, setreload] = useState(false);
  const [groups, setGroups] = useState(false);
  const [templateData, setTemplateData] = useState();
  const [groupData, setGroupData] = useState([]);
  
  const [unqGroup, setUnqGroup] = useState([]);
  const [groupDetail, setGroupDetail] = useState();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [templeteId, setTempleteId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [editQuestion, setEditQuestion] = useState(false);
  const [editQuestionId, setEditQuestionId] = useState(false);
  const [tempgroup, setTempgroup] = useState([]);
  const [groupListDetails, setGroupListDetails] = useState([]);
  const userId = useSelector((state) => state.auth.userId);
  const params = useParams();

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = (questionId) => {
    setShow(true);
   
  }

  const editQuestionHandler = (questionId) => {
    setEditQuestion(true);
    setEditQuestionId(questionId);
   
  }
  const editQuestionCloseModel =() => {
    setEditQuestion(false);
   
  }
  

  const handleClose2 = () => {
    setShow2(false);
  }
  const handleShow2 = () => {
    setShow2(true);
  }
  const handleClose1 = () => {
    setShow1(false);
  }
  const handleShow1 = () => {
    setShow1(true);
  }

  useEffect(() => {


    const fetchMyAPI = async () => {
      const template = await service.getTempleteById(userId, params.id);
      setTempleteId(params.id)
      setTemplateData(template.data);
      if (template.data.mainTemplate != null) {
        setQuestionList(template.data.mainTemplate)
      }
      setTempgroup(template.data.groupData);
      setIsLoading(false);
    }
    fetchMyAPI();

  }, [])

  


  useEffect( () => {
    const fetchgroupData = async () => {
    const temp= await service.getGroupTempletes(userId);
      setGroupListDetails(temp.data);
 
   }
    
    setShow(false);
    questionList.map((que) => {
      if ((que.questionType === "radio") || (que.questionType === "checkbox")) {
        que.group.map((gro) => {
          gro.group.map((subGou) => {
            var isNew = subGou.__isNew__;
            if (isNew === undefined) {
              isNew = false;
            }
            if (tempgroup !== null && tempgroup.length >= 1) {
              tempgroup.map((tempgroupData) => {
                if (tempgroupData.label === subGou.label) {
                  groupData.push({ value: subGou.value, label: subGou.label, newGroup: isNew, questionList: tempgroupData.questionList });
            } else {
                   }
              })
              if(isNew){
                groupData.push({ value: subGou.value, label: subGou.label, newGroup: isNew, questionList: [] });

              }
            } else {
              if (tempgroup == null && groupListDetails.length ==0) {
                fetchgroupData();
              }
              groupListDetails.map((tempgroupData) => {
                if (tempgroupData.label === subGou.label) {
                  groupData.push({ value: subGou.value, label: subGou.label, newGroup: isNew, questionList: tempgroupData.questionList });
                } else {
                }
              })
              if (tempgroup == null && groupListDetails.length ==0) {
                groupData.push({ value: subGou.value, label: subGou.label, newGroup: isNew, questionList: [] });
              }
             // groupData.push({ value: subGou.value, label: subGou.label, newGroup: isNew, questionList: [] });

            }
          })
          //  }
        })
      }
    })
    const uniqueIds = [];
    let uniqueEmployees = groupData.filter(
      ({ value, label,questionList }, index) => {
         const eer =  groupData.findIndex(item => (item.value == value && item.label == label 
        //&& item.value !== item.label 
        )) === index;
        return eer
      });
     setUnqGroup(uniqueEmployees);
    }, [questionList])

  const dragItem = useRef();
  const dragOverItem = useRef();
  const innerDragItem = useRef();
  const innerDragOverItem = useRef();
  const onDragStart = (e, index) => {

  }
  const onDragEnter = (e, index) => {

  }

  const onDragEnd = (e, index) => {

  }


  // handinling sorting 
  const handelsort = () => {
    let _oridata = [...questionList];
    if (innerDragItem.current === undefined) {
      if (dragItem.current < innerDragOverItem.current) {
        const _draggedItemContentsub = _oridata.splice(dragItem.current, 1)[0];
        _oridata.map((data, index) => {
          if (dragItem.current === index) {
            data.subQuestion.push(_draggedItemContentsub);
          }
        })
      } else if (dragItem.current > innerDragOverItem.current) {
        const _draggedItemContentsub = _oridata.splice(dragItem.current, 1)[0];
        _oridata.map((data, index) => {
          if (dragOverItem.current === index) {
            data.subQuestion.push(_draggedItemContentsub);
          }
        })
      }

    } else {
      const _draggedItemContent = _oridata.splice(dragItem.current, 1)[0];
      _oridata.splice(dragOverItem.current, 0, _draggedItemContent);
    }
    dragItem.current = null;
    dragOverItem.current = null;
    setQuestionList(_oridata);
  }

  const request = {
    templeteId: "",
    mainTemplate: "",
    groupData: "",
    requestType:""
  }
  const handleSave = async (e) => {
    setIsLoading(true);
    request.templeteId = templeteId;
    request.mainTemplate = questionList;
    request.groupData = unqGroup;
    request.requestType="saveTemplete";
    const template = await service1.saveTemplates(userId, request);
    if(!(template.ErrorCode != undefined)){
      popUp.MessagePopupComp('Template Saved successfully',constant.Success);
    }else {
    popUp.MessagePopupComp('Error in saving Template - '+template.message,constant.Error);
    }
  setIsLoading(false);
}

const handelPublish = async (e) => {
    setIsLoading(true);
    request.templeteId = templeteId;
    request.mainTemplate = questionList;
    request.groupData = unqGroup;
    request.requestType="publishTemplete";
    //setTemplateData(request);
    const template = await service1.saveTemplates(userId, request);
    if(!(template.ErrorCode != undefined)){
      popUp.MessagePopupComp('Template Saved successfully',constant.Success);
   }else {
    popUp.MessagePopupComp('Error in saving Template - '+template.message,constant.Error);
    }
  setIsLoading(false);
  }

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <div className='card m-2'>
            <div >
              <div className='d-flex justify-content-between'>

                <div className='col-5'>

                  {
                    isLoading ? ('Loading') : (
                      <div className=' me-1 mb-2 lh-1 p-4'>
                        <div className='fs-5 fw-bolder text-gray-900 text-hover-primary'>{templateData.templeteData.templeteName}</div>
                        <div className='pt-2'>{templateData.templeteData.templeteDesc}</div>
                      </div>
                    )
                  }

                </div>
                <div className='col-7'>
                  <div className='fs-5 d-flex justify-content-end fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1 p-4'>
                  <Link to="/auth/doc/create-pre-questions" type='submit' className="btn btn-primary rounded submit px-4" name="action"
                      >Back</Link>

                    <button type='submit' className="btn btn-primary rounded submit p-2 px-4" name="action"
                      onClick={handleSave}>
                        {!isLoading && <span className='indicator-label'>save</span>}
          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
                      </button>

{
  //<button type='submit' className="btn btn-primary rounded submit px-4 m-2" name="action" onClick={handelPublish}>publish</button>
}

                    <button type='submit' className="btn btn-primary rounded submit px-4" name="action"
                      onClick={handleShow2}>Add Question</button>
{
                   
isLoading ? ('Loading') : (<>
                    <PreviewModel groups={groups} setGroups={setGroups} show={show2} onHide={handleClose2} questionList={questionList} setQuestionList={setQuestionList} groupData={groupData} setGroupData={setGroupData} setGroupDetail={setGroupDetail} unqGroup={unqGroup} setUnqGroup={setUnqGroup}  templeteName={templateData.templeteData.templeteName} templeteType={templateData.templeteData.groupType} />

                    <PreviewModel groupDetail={groupDetail} groups={groups} setGroups={setGroups} show={show1} onHide={handleClose1} questionList={questionList} setQuestionList={setQuestionList} groupData={groupData} setGroupData={setGroupData} setGroupDetail={setGroupDetail} unqGroup={unqGroup} setUnqGroup={setUnqGroup} templeteName={templateData.templeteData.templeteName} templeteType={templateData.templeteData.groupType} />
                   
                    <PreviewModel groups={groups} setGroups={setGroups} show={editQuestion} onHide={editQuestionCloseModel} editQuestion={editQuestion} editQuestionId={editQuestionId} questionList={questionList} setQuestionList={setQuestionList} groupData={groupData} setGroupData={setGroupData} setGroupDetail={setGroupDetail} unqGroup={unqGroup} setUnqGroup={setUnqGroup} templeteName={templateData.templeteData.templeteName} templeteType={templateData.templeteData.groupType}/>
                    </>)}
                  
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <div className='card p-4'>
            {questionList &&
              questionList.map((question, idx) =>
                <QuestionCard question={question} setQuestionList={setQuestionList} questionList={questionList} handelsort={handelsort} dragItem={dragItem} dragOverItem={dragOverItem} idx={idx} onDragStart={onDragStart} onDragEnter={onDragEnter} onDragEnd={onDragEnd} innerDragItem={innerDragItem} innerDragOverItem={innerDragOverItem} handleShow={handleShow} show={show} handleClose={handleClose} reload={reload} setreload={setreload} formEditable={true} editQuestionHandler={editQuestionHandler}/>
              )
            }

          </div>
        </div>

      </div>
      <div className='row pt-4'>
        <div className='col-12'>
          <Accordion defaultActiveKey="0">
            
            {
              unqGroup.map((group, idx) =>
                <div className='card p-4 pt-2 m-2'>
                  <Accordion.Item eventKey={idx}>
                    <Accordion.Header>{group.label} #{idx}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className='d-flex justify-content-between'>
                        <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1 p-4'> </div>

                        <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1 p-4'>
                          <ButtonComp setGroupDetail={setGroupDetail} setShow={setShow1} group={group} key={idx} />
                        </div>
                      </div>
                     
                      {group.questionList !== null && (
                        group.questionList.map((question, idx) =>
                          <QuestionCard question={question} setQuestionList={setQuestionList} questionList={group.questionList} handelsort={handelsort} dragItem={dragItem} dragOverItem={dragOverItem} idx={idx} onDragStart={onDragStart} onDragEnter={onDragEnter} onDragEnd={onDragEnd} innerDragItem={innerDragItem} innerDragOverItem={innerDragOverItem} handleShow={handleShow} show={show} handleClose={handleClose} reload={reload} setreload={setreload} formEditable={true} editQuestionHandler={editQuestionHandler}/>
                        )
                      )

                      }
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              )
            }
          </Accordion>
        </div>
      </div>
    </div>
  )
}



function ButtonComp(props) {

  const { setGroupDetail, setShow, group } = props;
  const handleGroupQuestionShow = (event) => {
    setGroupDetail(event.target.value);
    setShow(true);
  }

  return (

    <button type='submit' className="btn btn-primary rounded submit px-4" name="action"
      onClick={handleGroupQuestionShow} value={JSON.stringify(group)}>Add Question</button>
  )

}
export default ManageQuestion