import React from 'react'
import { Route, Routes } from 'react-router-dom';
import SuperAdminLayout from './SuperAdminLayout';

function SuperAdminRouter() {
  return (
    <Routes>
            <Route element={<SuperAdminLayout />} >
            <Route index element={<SuperAdminLayout />} />
          {
            /*
            <Route path='book-appointment' element={<BookAppointment />} />
          */
        }
            
                </Route>
        </Routes>
  )
}

export default SuperAdminRouter