import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
      userId:null,
      menu :null,
      role:null,
      isAuthenticated:false,
      frontDeskCode:null,
      name:null,
      email :null,
     },
    reducers: {
      saveAuth: (state, action) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        if(action.payload !== undefined){
          state.userId=action.payload.data.p_id;
          state.menu=action.payload.data.menuDetails;
          state.role=action.payload.data.roleName;
          state.isAuthenticated=true;
          state.frontDeskCode=action.payload.data.frontDesktempleteURL;
          state.name = action.payload.data.firstName + " "+ action.payload.data.lastName;
          state.email = action.payload.data.email;
          
        }else {
          state.userId=null;
        state.menu=null;
        state.role=null;
        state.isAuthenticated=false;
        }
        
       // localStorage.setItem('authData', JSON.stringify(action.payload.data));
        //state.value += 1
      },
      
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { saveAuth } = authSlice.actions
  
  export default authSlice.reducer