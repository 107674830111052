import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../reducer/authReducer'
import GlobalState from '../reducer/GlobalState'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}
const persistedReducer = persistReducer(persistConfig, authReducer);

export default configureStore({
  reducer: {
    auth:persistedReducer,
    globalState:GlobalState
  },
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  },
  ),

  
})
