import React from 'react'
import OrgAdminLayout from './OrgAdminLayout'
import { Route, Routes } from 'react-router-dom';
import EmployeeAdmin from './EmployeeAdmin';
import DoctorSetting from './DoctorSetting';
export default function OrgAdminRouter() {
  return (
    <Routes>
    <Route element={<OrgAdminLayout />} >
    <Route path='admin-doctor' element={<DoctorSetting />} />
    <Route path='admin-employee' element={<EmployeeAdmin />} />
    <Route index element={<EmployeeAdmin />} />
  {
    /*
    <Route path='book-appointment' element={<BookAppointment />} />
  */
}
    
        </Route>
</Routes>
  )
}
