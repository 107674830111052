import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import * as service from '../../service/ApiService';
import { useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as popUp from '../../component/MessagePopup/MessagePopupComp';
import * as constant from '../../Utils/MapConstant';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import TextField from '@mui/material/TextField';

export default function BookAppointment() {

  const [doctor, setDoctor, date,setRefresh] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [disableCss, setDisableCss] = useState();
  const [slotDetails, setSlotDetails] = useState();
  const [age, setAge] = useState();
  const [dobValue, setDobValue] = useState();
  const [showError, setShowError] = useState(false);
  const [dobError, setDobError] = useState("");
  const [slotAvailableError, setSlotAvailableError] = useState();
  const [bookingId, setBookingId] = useState();
  const userId = useSelector((state) => state.auth.userId);
  const [isChecked, setIsChecked] = useState(false);
    const [value,setValue] =useState("");
    const handleOnChange = () => {
      setIsChecked(!isChecked);
    };

  const initialValues = {
    pId: '',
    mobile: '',
    email: '',
    fName: '',
    mName: '',
    lName: '',
    age: '',
    dob:'',
    genderV: 'Male',
    referredBy: ''
  }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const bookAppointmentSchema = Yup.object().shape({
    pId: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(15, 'Maximum 15 symbols'),
    mobile: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(9, "Phone number is too short")
    .max(11, "Phone number is too long"),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    fName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(15, 'Maximum 50 symbols')
      .required('First Name is required'),
    mName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(15, 'Maximum 50 symbols'),
    lName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(15, 'Maximum 50 symbols')
      .required('Last Name is required'),
      dob: Yup.string()
      .required("Date of birth is required"),
   // age: Yup.string()
    //  .min(1, 'Minimum 1 symbols')
    //  .max(3, 'Maximum 3 symbols')
    //  .required('Age is required'),
    referredBy: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(30, 'Maximum 30 symbols')



  })

  const request = {
    doctorId: "",
    slotDate: '',
    timingId: '',
    slotId: '',
    slotBucketId: '',
    patientDetails: "",
    appointmentExistFortheDay:false,
    bookingId:""
  }
  const person = {
    p_id: '',
    firstName: '',
    lastName: '',
    middleName: '',
    middleName: '',
    age: '',
    dob:'',
    gender: '',
    mobileNumber: '',
    email: '',
    username: '',
  }

  
  const formik = useFormik({
    initialValues,
    validationSchema: bookAppointmentSchema,


    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        //const data = await service.login(values.email,values.password);
        // dispatch(saveAuth(data));
        //navigate('/auth/dashboard');
     
        if (slotDetails == undefined) {
          setShowError('Please select appointment Slot');
        } else {
     
          let jsonValue = JSON.parse(slotDetails)
          setLoading(true);

          request.doctorId = doctor.doctorDetails.p_id;
          request.slotDate = date;
          request.slotId = jsonValue.slotId;
          request.timingId = jsonValue.timingId;

          person.p_id = values.pId;
          person.age = values.age;
          person.email = values.email;
          person.firstName = values.fName;
          person.lastName = values.lName;
          person.gender = values.genderV;
          person.middleName = values.mName;
          person.mobileNumber = values.mobile;
          person.dob = values.dob;
          request.patientDetails = person;
          if(slotAvailableError == undefined){
            request.appointmentExistFortheDay = false;
          }else {
            request.appointmentExistFortheDay = true;
            request.bookingId =bookingId;
          }
          const data = await service.bookAppointment(userId, request);
          setLoading(false);
          formik.resetForm();
          setSlotDetails(null);
          setShowError("");
          setDoctor(data.data);
          setRefresh(true);
          setDobValue(null);
          setAge("");
          popUp.MessagePopupComp('Appointment booked Successfully',constant.Success);
        }
       } catch (error) {
        setStatus('The login details are incorrect book');
        setSubmitting(false);
        setLoading(false);
        popUp.MessagePopupComp('Error in booking Appointment',constant.Error);
       
      }
      setSlotAvailableError(undefined);
    }

  })
  const setDob =(value ) =>{
console.log("setDob ::"+value)
let years=moment().diff(moment(value, 'DD-MM-YYYY'), 'years')
console.log("years ::"+years + " isNaN(years) ::"+isNaN(years) )
if( !isNaN(years) && years >= 0 && years <= 120){
  formik.setFieldValue("dob", value)
  formik.setFieldValue("age", years)
  setAge(years+" Years");
  //setDobValue(value);
  setDobError("");
}else{
  console.log("In else invalid datew")
  setDobError("Invalid date")
  setAge("");
  //setDobValue(null);
}

  }
  

  async function getDetailsByPid(e) {
    setDisableCss("display-disable");
    const data = await service.getDetailsById(userId, e.target.value,date,doctor.doctorDetails.p_id).then(
      (response) => {
        
        if (response.data != "") {
          formik.setFieldValue("email", response.data.email)
          formik.setFieldValue("fName", response.data.firstName);
          formik.setFieldValue("lName", response.data.lastName)
          formik.setFieldValue("age", response.data.age)
          formik.setFieldValue("gender", response.data.gender)
          formik.setFieldValue("mobile", response.data.mobileNumber)
          setDisableCss("");
          if(response.data.slotDetails !== undefined){
            setBookingId(response.data.slotDetails);
            setSlotAvailableError("Already have an Apointment for the same day, Creating appointment will override the slot for some day");
          }
        }else {
          //formik.resetForm();
        }
      }
    );
  }

  async function getDetailsByMobileNumber(e) {
    setDisableCss("display-disable");
    const data = await service.getDetailsByMobileNumber(userId, e.target.value,date,doctor.doctorDetails.p_id).then(
      (response) => {
        console.log("response ::"+JSON.stringify(response))
        if (response.data.length == undefined) {
          formik.setFieldValue("pId", response.data.p_id)
          formik.setFieldValue("email", response.data.email)
          formik.setFieldValue("fName", response.data.firstName);
          formik.setFieldValue("lName", response.data.lastName)
          formik.setFieldValue("age", response.data.age)
          formik.setFieldValue("gender", response.data.gender)
          formik.setFieldValue("mobile", response.data.mobileNumber)
          setDisableCss("");
          if(response.data.slotDetails != 0  ){
            console.log("In response.data.slotDetails in if ::"+response.data.slotDetails)
            setBookingId(response.data.slotDetails);
           setSlotAvailableError("Already have an Apointment for the same day, Creating appointment will override the slot for some day");
          }
        }else {
          //formik.resetForm();
        }

      }
    );
  }
  console.log("value::"+value)
  return (
    <div className='row'>
      <div className='col-7'>
        <div className='card p-3 border-radius'>
          <div className={'card-header ' + disableCss}>
            <div className='d-flex justify-content-between'>
              <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>Appointments for Dr. {doctor.doctorDetails.firstName + " " + doctor.doctorDetails.lastName}</div>
           
          </div>

            
          </div>
          
          {slotAvailableError} 
          <form className="login-form" onSubmit={formik.handleSubmit}>
            <div className='card-body  ' >
         <div class="col-lg-12 col-xl-12 pt-4" >
                <div class="row">
                  {
                    /*
<div class="col">
                    <div class="form-group floating-label">
                      <input type="text" class="form-control" placeholder=" " name="pId"
                        {...formik.getFieldProps('pId')}
                        className={clsx(
                          'form-control',
                          { 'is-invalid': formik.touched.pId && formik.errors.pId },
                          {
                            'is-valid': formik.touched.pId && !formik.errors.pId,
                          }
                        )}
                        onBlur={getDetailsByPid}
                      />
                      <label for="text">Paitent Id</label>
                    </div>
                  </div>

                    */
                  }
                  
                  <div class="col">
                    <div class="form-group floating-label">
                      <input type="text" class="form-control" placeholder=" " name="mobile"
                        {...formik.getFieldProps('mobile')}
                        className={clsx(
                          'form-control',
                          { 'is-invalid': formik.touched.mobile && formik.errors.mobile },
                          {
                            'is-valid': formik.touched.mobile && !formik.errors.mobile,
                          }
                        )}
                        onBlur={getDetailsByMobileNumber}
                      />
                      
                      <label for="text">Mobile Number</label>
                    </div>{
                       <div className='error-msg'>{formik.errors.mobile}</div>
                    }
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-xl-12 pt-4">
                <div class="form-group floating-label">
                  <input type="text" class="form-control" placeholder=" " name="email"
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control',
                      { 'is-invalid': formik.touched.email && formik.errors.email },
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )} />
                  <label for="text">Email</label>
                </div>
                {
                    formik.touched.email && formik.errors.email && <div className='error-msg'>{formik.errors.email}</div>
                    }
              </div>
              
              <div class="col-lg-12 col-xl-12 pt-4">
                <div class="row">
                  <div class="col">
                    <div class="form-group floating-label">
                      <input id="text" class="form-control" placeholder=" " name="fName"
                        {...formik.getFieldProps('fName')}
                        className={clsx(
                          'form-control',
                          { 'is-invalid': formik.touched.fName && formik.errors.fName },
                          {
                            'is-valid': formik.touched.fName && !formik.errors.fName,
                          }
                        )} />
                      <label for="text">First Name </label>
                    </div>
                    
                  </div>
                  <div class="col">
                    <div class="form-group floating-label">
                      <input type="text" class="form-control" placeholder=" " name="mName"
                        {...formik.getFieldProps('mName')}
                        className={clsx(
                          'form-control',
                          { 'is-invalid': formik.touched.mName && formik.errors.mName },
                          {
                            'is-valid': formik.touched.mName && !formik.errors.mName,
                          }
                        )}
                      />
                      <label for="text">Middle Name</label>
                    </div>
                    
                  </div>
                  <div class="col">
                    <div class="form-group floating-label">
                      <input type="text" class="form-control" placeholder=" " name="lName"
                        {...formik.getFieldProps('lName')}
                        className={clsx(
                          'form-control',
                          { 'is-invalid': formik.touched.lName && formik.errors.lName },
                          {
                            'is-valid': formik.touched.lName && !formik.errors.lName,
                          }
                        )}
                      />
                      <label for="text">Last Name</label>
                    </div>
                   
                  </div>
                  {
                     formik.touched.lName && formik.errors.lName  && <div className='error-msg'>{formik.errors.lName}</div>
                    }
                     {
                     formik.touched.mName && formik.errors.mName  && <div className='error-msg'>{formik.errors.mName}</div>
                    }
                     {
                     formik.touched.fName && formik.errors.fName  && <div className='error-msg'>{formik.errors.fName}</div>
                    }
                 
                </div>
              </div>
              
              <div class="col-lg-12 col-xl-12 pt-4">
                <div class="row">
                
                 
                    <div class="col">
                    <div className="TextField-without-border-radius">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                    value={dobValue}
                                    name="dob"
                                    
                                    //clearText="Clear me"
                                     //clearable={true}
                                     renderInput={(params) => <TextField {...params} InputProps={{
                                      style: {
                                        borderRadius: "10px",
                                      }
                                    }}/>}
                                  //className="myDatePicker"
                                        label="Date of birth"
                                        format='DD-MM-YYYY'
                                        onChange={(newValue) => setDob(dayjs(newValue).format('DD-MM-YYYY'))}
                                        onBlur={(newValue) => setDob(newValue)}
                                        slotProps={{ textField: { size: 'small' } }}
                                       
                                   />
                                </LocalizationProvider>
</div>
                                {
                     dobError  && <div className='error-msg'>{dobError}</div>
                    }
    </div>
    <div class="col">
      
      <label className="form-check-label font-14 pt-2 font-bold" >{age} </label>
                    </div>
                    </div>
                  </div>
              <div class="col-lg-12 col-xl-12 pt-4">
                <div class="row">
                <div className='col-2'>Gender</div> 
                  <div class="col-10">
                 
                    <div className="form-check form-check-inline">
                      <input className={"form-check-input font-14"}
                        name="genderV"
                        type="radio"
                        value="male"
                        id="maleId"
                        onChange={formik.handleChange}
                        {...formik.getFieldProps('genderV')}
                      />
                      <label className="form-check-label font-14" for="inlineRadio1">Male </label>
                    </div>
                    <div className="form-check form-check-inline">

                      <input className={"form-check-input font-14"}
                        name="genderV"
                        type="radio"
                        value="female"
                        id="femaleId"
                        onChange={formik.handleChange}
                        {...formik.getFieldProps('genderV')}
                      />
                      <label className="form-check-label font-14" for="inlineRadio2">Female</label>
                    </div>
                  </div>
                  {
                     formik.touched.gender && formik.errors.gender && <div className='error-msg'>{formik.errors.gender}</div>
                    }
                </div>
              </div>
              <div class="col-lg-12 col-xl-12 pt-4">
                <div class="form-group floating-label">
                  <input id="text" class="form-control" placeholder=" " name="referredBy"
                    {...formik.getFieldProps('referredBy')}
                    className={clsx(
                      'form-control',
                      { 'is-invalid': formik.touched.referredBy && formik.errors.referredBy },
                      {
                        'is-valid': formik.touched.referredBy && !formik.errors.referredBy,
                      }
                    )}
                  />
                  <label for="text">Reffered by</label>
                </div>
                {
                     formik.touched.referredBy && formik.errors.referredBy && <div className='error-msg'>{formik.errors.referredBy}</div>
                    }
              </div>


             

            </div>
            <div className='d-flex justify-content-between'>
              <div>
                <button type='submit' className="btn btn-primary rounded submit p-2 px-4">reset</button>
              </div>

              <div>
                <button
                  type='submit'

                  className="btn btn-primary rounded submit"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className='indicator-label'>Book Appointment </span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>


            </div>
          </form>
        </div>
      </div>
      <div className='col-5'>
        <div className='card p-3 border-radius'>
          <div className={'card-header '}>
            <div className='d-flex justify-content-between'>
              <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>Appointment slot for {doctor.timingAndSlots[0].day}</div>
            </div>
          </div>
          <div className='card-body  ' >
            {doctor.timingAndSlots.map((slotsD, idx) =>
              <AppointmentSlot slotDetails={slotsD.slotsDetails} setSlotDetails={setSlotDetails} showError={showError} setShowError={setShowError} date={date} noOfPersonPerSlot={doctor.noOfPersonPerSlot} />
            )
            }
          </div>
        </div>
      </div>
    </div>
  )
}


function AppointmentSlot(props) {
  const { slotDetails, setSlotDetails, showError, setShowError, date ,noOfPersonPerSlot} = props;

 useEffect(() => {
  
  if(slotDetails.length ===0){
    setShowError("Doctor not available for that day ")
  }else {
    setShowError(undefined)
  }
 })


  return (
    <>
      {
        showError && (
          <div className='danger-alert'>{showError} </div>
        )
      }

      {slotDetails.map((slotsD, idx) =>
        <>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{slotsD.timing.startTiming} - {slotsD.timing.endTiming}</Accordion.Header>
              <Accordion.Body>

                <div className='row'>

                  {
                    slotsD.slots.map((slotsN, idx) =>
                      <div className='col-6'>
                        <SlotDetails date={date} slotPerDetails={slotsN} timingId={slotsD.timing.id} setSlotDetails={setSlotDetails} key={showError} setShowError={setShowError} showError={showError} noOfPersonPerSlot={noOfPersonPerSlot}/>
                      </div>
                    )
                  }

                </div>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      )
      }
    </>
  )
}

function SlotDetails(props) {

  const { slotPerDetails, timingId, setSlotDetails, index, showError, setShowError, date ,noOfPersonPerSlot} = props;
  const slotIdValue = { "timingId": timingId, "slotId": slotPerDetails.id };
  const [bookingCss, setBookingCss] = useState('');

  useEffect(() => {
    if ((slotPerDetails.bookedCount / noOfPersonPerSlot *100) < 50) {
      setBookingCss("slot-button-available")
    } else if ((slotPerDetails.bookedCount / noOfPersonPerSlot *100) == 50 && (slotPerDetails.bookedCount / noOfPersonPerSlot *100) < 100) {
      setBookingCss("slot-button-semi")
    }else if ((slotPerDetails.bookedCount / noOfPersonPerSlot *100) ==100){
      setBookingCss("slot-button-booked")
    }
  }
  , [date, slotPerDetails])

  const setValue = (event) => {
    if (slotPerDetails.bookedCount >= 100) {
      setShowError("Slot not available");
      setBookingCss("slot-button-booked");
    } else {
      setSlotDetails(event.target.value);
    }
  };
  


  return (
    <>
      {

        <div className='p-1'>

          <input type="radio"
            class="btn-check"
            name={'slotDetails'}
            value={JSON.stringify(slotIdValue)}
            id={timingId + "" + slotPerDetails.id} autocomplete="off"
            onClick={setValue}
          />
          <label class={"btn slot-button " + bookingCss} for={timingId + "" + slotPerDetails.id} id={timingId + "" + slotPerDetails.id}>{slotPerDetails.startTiming} - {slotPerDetails.endTiming}</label>
        </div>

      }
    </>
  )
}