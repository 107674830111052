import React from 'react'
import { useState,useEffect } from 'react';
import * as Icon from 'react-bootstrap-icons';
import PreviewModel from '../modelPopup/PreviewModel';
import InputOptionLoader from './InputOptionLoader';
import { useSelector } from 'react-redux';

function QuestionCard(props) { 
    const { question,setQuestionList,questionList,groupData,setGroupData,handleShow,show,handleClose,setreload,reload,index,handleChange,formEditable,editQuestionHandler } = props;
    const [editable,setEditable] =useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isPrinatableChecked, setIsPrinatableChecked] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const userId = useSelector((state) => state.auth.userId);
    const role = useSelector((state) => state.auth.role);
   // const userId = useSelector((state) => state.auth.userId);
    useEffect(() => {
      setEditable(formEditable)
      if(question.privateQuestion == true){
        setIsChecked(true)
      }
      if(question.printable == true){
        setIsPrinatableChecked(true)
      }
    }, [])
    

    function onClickEdit (questionId){
      editQuestionHandler(questionId);
    }

    function makeQuestionPrivate(){
       
        questionList.map((questionI,indx) =>{
            if(questionI.questionId === question.questionId){
               //questionList.splice(indx,1);
              // setQuestionList(questionList);
              setIsChecked(!isChecked);
              questionI.privateQuestion=!isChecked;
            }
          })
         
    }

    function makeQuestionPrintable(){
       
        questionList.map((questionI,indx) =>{
            if(questionI.questionId === question.questionId){
               //questionList.splice(indx,1);
              // setQuestionList(questionList);
              setIsPrinatableChecked(!isPrinatableChecked);
              questionI.printable=!isPrinatableChecked;
            }
          })
         
    }

    function onClickDelete(){

        setQuestionList((questionList) => {
            return [
                ...questionList.filter((item, i) => item.questionId !== question.questionId)
            ]
        }
        )

        questionList.map((questionI,indx) =>{
            if(questionI.questionId === question.questionId){
               questionList.splice(indx,1);
              // setQuestionList(questionList);
               
            }
          })
          setreload(!reload);
          }

          function updatePrintMsg(value){
          
            questionList.map((questionI,indx) =>{
              if(questionI.questionId === question.questionId){
                 //questionList.splice(indx,1);
                // setQuestionList(questionList);
                //setIsPrinatableChecked(!isPrinatableChecked);
                questionI.printMsg=value;
              }
            })
            //setDisabled(!disabled);
            //handleChange(id, value, inputValue);
            //setSelected(value);
           }

           function printMsgBtn(value){
           
            setDisabled(!disabled);
            //handleChange(id, value, inputValue);
            //setSelected(value);
           }

           
  
    return (
       <div className='card p-2 mt-2'>
            
                <div className='row'>
                    <div className='col-11'>
                    <div className='ms-2 wid100'>
                        <div className='col-12' >
                            <div class="form-group floating-label ">
                                <div className='question-style'>
                                    {question.question}</div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='innerdiv'
                               >
                                <InputOptionLoader question={question} inputId={index} handleChange={handleChange}/>
                            </div>
                        </div>
                      { role == 'doctor' &&
                        question.printable == true && <div><div className='d-flex justify-content-between pt-4'>
                           <input className="form-control "
              //  name={question.questionId }
                type="text"
                id={question.questionId }
                defaultValue={question.printMsg }
                onChange={(e) => updatePrintMsg( e.target.value)}
                disabled={disabled}
            />
            <div className='px-2 pt-1'  >  <Icon.PencilSquare size={30} onClick={printMsgBtn}/> </div>
                          
                          </div>
                        <div className='px-4 font-10' >  Please feel free to edit the message, but make sure to use (answer-data) placeholder anywhere in sentence to replace with actual answer  </div>
                          </div>
                      }
                      <div>

                      </div>
                        
                        { 
                      
                      editable &&  question.group.length > 0 &&
                            question.group.map((data) =>
                            <DiplayGroupDetails groupsToDisplay={data} label={data.optionLabel}/>
                        )
                         
                        }
                         <div>
                    </div>
                        
                    </div>
                    </div>
                   
                   
                </div>

                {
                     editable && <div class="card-footer bg-pri-lite">
                     <div className=' d-flex align-items-center justify-content-center'>
                           {
                            
                                <div className='px-2' onClick={(e) =>onClickEdit(question.questionId)}> Edit <Icon.PencilSquare size={20}/> </div>
                            
                           } 
                             <div className='px-2' onClick={onClickDelete}>Delete <Icon.Archive size={20}/></div>
     
                    <div className='px-2 pt-1'>Make private
                             <label class="switch">
                       <input type="checkbox" name='toggleBtn' checked={isChecked}  onClick={makeQuestionPrivate}/>
                       <span class="slider round"></span>
                     </label>
                     </div>
                     <div className='px-2 pt-1'>Print 
                             <label class="switch">
                       <input type="checkbox" name='toggleBtn' checked={isPrinatableChecked}  onClick={makeQuestionPrintable}/>
                       <span class="slider round"></span>
                     </label>
                     </div>
                             </div>
       </div>
                }
                
            
        </div>
        
    )
}

function DiplayGroupDetails(props){

    const {groupsToDisplay,label} =props;
    
    return (
        <div>On selecting <span className='font-bold'>{label}</span> option, It will route to following{
            groupsToDisplay.group !== "" &&
            groupsToDisplay.group.map((inData,indx) =>
                               <span className='font-bold'> {inData.label } 
                               {  indx !== parseInt(groupsToDisplay.group.length-1) && ", "}
                               </span>
                            )
                            
            }  section/Group</div>
    )
}

export default QuestionCard