import React from 'react'
import MenuItem from './MenuItem';

function MenuWrapper(props) {
    const {content} =props;
  return (
    <div>
        {
        content.map((menuItem)=>
    <>
    <div>
    <MenuItem uri={menuItem.uri} displayName={menuItem.DisplayName} />
    </div>
    </>
)}
    </div>
  )
}

export default MenuWrapper