import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL
export const GET_DOCTORS = `${API_URL}/doctors`;
const GET_USER_DETAILS = `${API_URL}/user-details`;
const GET_USER_DETAILS_MOBILE_NUMBER = `${API_URL}/user-details/mobile-number`;
const GET_DOCTOR_DETAILS = `${API_URL}/doctorTiming/`;
const BOOK_APPOINTMENT = `${API_URL}/bookappointment`;
const UPDATE_APPOINTMENT_STATUS = `${API_URL}/changeBookingStatus`;
const APPOINTMENTS = `${API_URL}/bookingHistroy`;
const SAVE_TEMPLATE = `${API_URL}/savetemplate`;
const SAVE_SLOT_TIMING = `${API_URL}/doctorTiming`;
const DEFAULT_CASESHEET = `${API_URL}/get-default-casesheet`;
const CREATE_USER = `${API_URL}/create-user`;
const GET_USERS = `${API_URL}/get-users`;
const GET_STOL_TIMING = `${API_URL}/slotDetails/`;
// Server should return AuthModel
export function getDoctors(userId) {
  const config = {
    headers: {
      'userId': userId,
    }
  };
  return axios.get(GET_DOCTORS,
    config
  )
}

export function getDefaultCasesheetTemplate(userId) {
  const config = {
    headers: {
      'userId': userId,
    }
  };
  return axios.get(DEFAULT_CASESHEET,
    config
  )
}

// Server should return AuthModel
export function getDoctor(userId, doctorId, date) {
  let COMP_GET_DOCTOR_DETAILS = GET_DOCTOR_DETAILS.concat("/" + doctorId);
  const config = {
    headers: {
      'userId': userId,
      'dateDetails': date
    }
  };
  return axios.get(COMP_GET_DOCTOR_DETAILS,
    config
  ).catch((error) => {
    return error.response.data;
  })
}

export function getDetailsById(userId, pId, date, doctorId) {
  let COMP_GET_USER_DETAILS = GET_USER_DETAILS.concat("/" + pId);
  const config = {
    headers: {
      'userId': userId,
      'user-date': date,
      'doctorId': doctorId
    }
  };
  return axios.get(COMP_GET_USER_DETAILS,
    config
  )
}

export function getDetailsByMobileNumber(userId, mobileNumber, date, doctorId) {
  let COMP_GET_USER_DETAILS_MOBILE_NUMBER = GET_USER_DETAILS_MOBILE_NUMBER.concat("/" + mobileNumber);
  const config = {
    headers: {
      'userId': userId,
      'user-date': date,
      'doctorId': doctorId
    }
  };
  return axios.get(COMP_GET_USER_DETAILS_MOBILE_NUMBER,
    config
  )
}

export function bookAppointment(userId, bookAppointmentObj) {
  const config = {
    headers: {
      'userId': userId
    },
  };
  return axios.post(BOOK_APPOINTMENT, bookAppointmentObj, config)
}

export function getAppointments(userId, doctorId, date) {
  let APPOINTMENTS_URI = APPOINTMENTS.concat("/" + doctorId);
  const config = {
    headers: {
      'userId': userId,
      'dateDetails': date
    }
  };
  return axios.get(APPOINTMENTS_URI,
    config
  )
}

export function changeAppointmentStatus(userId, appointmentStatus) {

  const config = {
    headers: {
      'userId': userId
    },
    //  body:{bookAppointmentObj}
  };
  return axios.put(UPDATE_APPOINTMENT_STATUS, appointmentStatus, config)
}

export function saveTemplates(userId, templateDate) {
  const config = {
    headers: {
      'userId': userId
    },
    //body:{templateDate}
  };
  return axios.post(SAVE_TEMPLATE, templateDate, config).then((res) => {
    return res.data
  })
    .catch((error) => {
      return error.response.data;
    })
}

export function createUser(userId, createUserObj) {
  const config = {
    headers: {
      'userId': userId
    },
    //body:{templateDate}
  };
  return axios.post(CREATE_USER, createUserObj, config).then((res) => {
    return res.data
  })
    .catch((error) => {
      return error.response.data;
    })
}



export function getUsers(userId) {
  const config = {
    headers: {
      'userId': userId
    },
    //body:{templateDate}
  };
  return axios.get(GET_USERS, config).then((res) => {
    return res.data
  })
    .catch((error) => {
      return error.response.data;
    })
}

export function getSlotTimingDetails(userId) {
  let GET_STOL_TIMING_URI = GET_STOL_TIMING.concat("/" + userId
  );
  const config = {
    headers: {
      'userId': userId
    },
    //body:{templateDate}
  };
  return axios.get(GET_STOL_TIMING_URI, config).then((res) => {
    return res.data
  })
    .catch((error) => {
      return error.response.data;
    })
}

export function saveSlotTimimg(userId, slotDetails) {
  const config = {
    headers: {
      'userId': userId
    },
    //body:{templateDate}
  };
  return axios.put(SAVE_SLOT_TIMING, slotDetails, config).then((res) => {
    return res.data
  })
    .catch((error) => {
      return error.response.data;
    })
}