import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import App from '../App'
import AuthRouter from '../module/auth/AuthRouter';
import ErrorPage from '../module/errors/ErrorPage';
import PrivateRouter from './PrivateRouter';
import { useSelector } from 'react-redux';
import CaseSheetSurveyLayout from '../module/caseSheetsurvey/CaseSheetSurveyLayout';


function AppRouters() {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const currentUser =isAuthenticated;
  return (
    <BrowserRouter>
        <Routes>
            <Route element={<App/>}>
                <Route path='error/*' element={<ErrorPage/>} />
                <Route path='casesheet-survey/:templeteId/:userId' element={<CaseSheetSurveyLayout/>} />
                {currentUser ? (
                    <>
                    <Route path='/*' element={<PrivateRouter/>} />
                    <Route index element={<Navigate to='/dashboard' />} />
                    </>
                ) : (
                    <>
                    <Route path='auth/*' element={<AuthRouter />} />
                    <Route path='*' element={<Navigate to='/auth' />} />
                    </>
                )
                }
            </Route>
        </Routes>
    </BrowserRouter>
  )
}

export default AppRouters