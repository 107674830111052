import React from 'react'
import PaitentCasesheetLayout from './PaitentCasesheetLayout'; 
import { Route, Routes } from 'react-router-dom';

function AppointmentRouter() {
  return (
    <Routes>
            <Route element={<PaitentCasesheetLayout />} >
            <Route index element={<PaitentCasesheetLayout />} />
                </Route>
        </Routes>
  )
}

export default AppointmentRouter
