import React from 'react'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function WeightImputComponent() {
    const [alignment, setAlignment] = React.useState('Kg');

    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    };
  return (
    <div className='p-4'>
      <div className='row'>
      <div className='col-9'>
      <input type="text" className="form-control col-4" />
        </div>
      <div className='col-3'>
    
      <ToggleButtonGroup
  color="primary"
  value={alignment}
  exclusive
  onChange={handleChange}
  aria-label="Platform"
  size="small"
>
  <ToggleButton value="kg">Kg</ToggleButton>
  <ToggleButton value="lb">lb</ToggleButton>
 </ToggleButtonGroup>
      </div>
    </div>
 
    </div>
  )
}
