import React,{useEffect} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import * as service from './../../service/SurveyService';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from '../../Utils/MapConstant';
import * as popUp from '../../component/MessagePopup/MessagePopupComp';

function CreateTemplete(props) {
    const { show, onHide,templeteProp } = props;
    const userId = useSelector((state) => state.auth.userId);
    const navigate = useNavigate();
    const {templateData,setTemplateData} = useState();
    const initialValues = {
        templeteId:0,
        templeteName: '',
        templeteDesc: '',
        formtype:''
    }

    useEffect(() => {
        //setTemplateData(template);
        if(templeteProp != undefined){
            initialValues.templeteId = templeteProp.templeteId;
           initialValues.templeteName= templeteProp.templeteName;
        initialValues.templeteDesc= templeteProp.templeteDesc;
        }
    })
    

    const template = Yup.object().shape({
        templeteName: Yup.string()
            .required('Mobile is required')
            .min(3, 'Minimum 3 symbols')
            .max(75, 'Maximum 75 symbols'),
        templeteDesc: Yup.string()
            .required('Mobile is required')
            .min(9, 'Minimum 3 symbols')
            .max(500, 'Maximum 500 symbols'),
            templeteId: Yup.string(),
        formtype :Yup.string()
        .required('Form type is required')
    })

   
        const next = async (e) => {

//const template = await service.CreateTemplete(userId, request);
//navigate("/auth/doc/manage-templete/12");
        //onHide();
    }
    const formik = useFormik({
        initialValues,
        validationSchema: template,
        enableReinitialize:true,

        onSubmit: async (values, { setStatus, setSubmitting }) => {
            try {
                initialValues.templeteName = values.templeteName;
                initialValues.templeteDesc = values.templeteDesc;
                initialValues.formtype = values.formtype;
                if(templeteProp !== undefined){
                    initialValues.templeteId = values.templeteId;
                    const templateww = await service.updateTemplete(userId, initialValues);
                    if(!(templateww.ErrorCode != undefined)){
                        popUp.MessagePopupComp('Template metadata Updated successfully, Complete Template by adding question',constant.Success);
                       }else {
                        popUp.MessagePopupComp('Error in Updating Templete Metadata',constant.Error);
                        }
                    onHide();
                }else {
                   
                    const template = await service.createTemplete(userId, initialValues);
                    if(!(template.ErrorCode != undefined)){
                        popUp.MessagePopupComp('Template metadata Created successfully, Complete Teamplate by adding question',constant.Success);
                       }else {
                        popUp.MessagePopupComp('Error in Saving Templete Metadata',constant.Error);
                        }
                    navigate("/auth/doc/manage-templete/"+template.data.templeteId);
                }
            } catch (error) {
               
            }

        }

    })

    return (
        <form className="login-form" onSubmit={formik.handleSubmit}>
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Create Form Templete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    templeteProp != undefined &&
                    <input type="text" class="form-control" placeholder=" " id="templeteId" name="templeteId"
                                        {...formik.getFieldProps('templeteId')}
                                        disabled={true}  
                                    />
                }
            
                    <div class="col-lg-12 col-xl-12 pt-4" >
                        <div class="row">
                            <div class="col">
                                <div class="form-group floating-label">
                                    <input type="text" class="form-control" placeholder=" " id="templeteName" name="templeteName"
                                        {...formik.getFieldProps('templeteName')}
                                        className={clsx(
                                            'form-control',
                                            { 'is-invalid': formik.touched.templeteName && formik.errors.templeteName },
                                            {
                                                'is-valid': formik.touched.templeteName && !formik.errors.templeteName,
                                            }
                                        )}
                                    />
                                    <label for="text">Templete Name </label>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-lg-12 col-xl-12 pt-4" >
                        <div class="row">
                            <div class="col">
                                <div class="form-group floating-label">
                               
                                   <div class="form-group floating-label">
      <textarea id="templeteDesc"  rows="4" cols="50"  type="textarea" placeholder=" "  name="templeteDesc" 
       {...formik.getFieldProps('templeteDesc')}
       className={clsx(
        'form-control h100',
        { 'is-invalid': formik.touched.templeteDesc && formik.errors.templeteDesc },
        {
            'is-valid': formik.touched.templeteDesc && !formik.errors.templeteDesc,
        }
    )}
              />
              <label htmlFor="text">Templete Desc </label>
              
            </div>

            <div className="form-group  floating-label pt-4">
                <div className="mb-3">

                  <select 
                   className={clsx(
                    'form-control form-select form-select-solid form-select-md',
                    { 'is-invalid': formik.touched.templeteDesc && formik.errors.templeteDesc },
                    {
                        'is-valid': formik.touched.templeteDesc && !formik.errors.templeteDesc,
                    }
                )}
                  {...formik.getFieldProps('formtype')}
                  name="formtype" >
                  <option value="select">Select Form type</option>
                  <option value="casesheet">Casesheet</option>
                  <option value="frontdesk">Frontdesk Form</option>
            </select>
           </div>
              </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={formik.handleSubmit}>Next</Button>
            </Modal.Footer>
        </Modal>
        </form>
    )
}

export default CreateTemplete