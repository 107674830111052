import React,{useState,useEffect} from 'react'
import * as service from './../../service/SurveyService';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from '../../Utils/MapConstant';
import * as popUp from '../../component/MessagePopup/MessagePopupComp';

const CasesheetSetter = (props) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [templates, setTemplates] = useState();

    const userId = useSelector((state) => state.auth.userId);

  const { doctor } = props;
  const [inputFields, setInputFields] = useState(
    { userId: '', templateId: '' , templeteType:'casesheetTemplete'}
  );

  useEffect(() => {
    const fetchMyAPI = async () => {
      const template = await service.getTempletes(userId);
      setTemplates(template.data);
      setIsLoading(false);
    }
    fetchMyAPI();
  }, [])
  const data ={
    userId : "",
    templateId:""
  }

  async function onChangeDoctorSetting(event) {
    inputFields.userId =event.target.id;
    inputFields.templateId = event.target.value;
    }

  async function saveDoctorSetting(event) {
    data.userId =userId;
    const templateData = await service.saveDoctorSetting(userId, inputFields);
    if(!(templateData.ErrorCode != undefined)){
      popUp.MessagePopupComp('Casesheet setting saved',constant.Success);
      }else {
        popUp.MessagePopupComp('Error saving Casesheet setting ',constant.Error);
      }

    }
 return (
    <>
    <div className='card p-3 m-2'>
    <div class="form-group row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Casesheet Templete</label>
    <div class="col-sm-10">
    <select class="form-select" aria-label="Default select example" id={doctor} onChange={onChangeDoctorSetting} name="templateDoctorId">
        <option selected>Select Casesheet Templete</option>
        {
          isLoading ? ("Loading") : (
            templates.map((templete) =>
              <>{
                (templete.templeteType === 'TM' &&  templete.groupType=='casesheet')&&
                <>
                  { 
                  templete.default ? (<option value={templete.templeteId} selected>{templete.templeteName}</option>) :
                  (<option value={templete.templeteId}>{templete.templeteName}</option>)
                      
                  }
                </>
              }
              </>
            )
          )
        }
        
      </select>
     </div>
  </div>
  <div className='d-flex justify-content-end pt-2'>
  <button type='submit' className="btn btn-primary rounded submit p-2 px-4" onClick={saveDoctorSetting}>Save</button>
</div>
    </div>
    </>
  )
}

export default CasesheetSetter