import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import MasterLayout from '../layout/MasterLayout'
import AppointmentModule from '../module/appointmentModule/AppointmentLayout'
import AppointmentRouter from '../module/appointmentModule/AppointmentRouter'
import CaseSheetSurveyLayout from '../module/caseSheetsurvey/CaseSheetSurveyLayout'
import CaseSheetWrapper from '../module/caseSheetsurvey/CaseSheetWrapper'
import DoctorRouter from '../module/doctors/DoctorRouter'
import OrgAdminRouter from '../module/orgAdmin/OrgAdminRouter'
import SuperAdminRouter from '../module/superAdmin/SuperAdminRouter'

import DashboardWrapper from '../pages/dashboard/DashboardWrapper'

function PrivateRouter() {
    return (
        <Routes >
            <Route element={<MasterLayout />}>
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                <Route path='dashboard' element={<DashboardWrapper />} />
                <Route path='auth/bk/*' element={<AppointmentRouter />} />
                <Route path='auth/doc/*' element={<DoctorRouter />} />
                <Route path='*' element={<Navigate to='/error/404' />} />
                <Route path='auth/casesheet-survey' element={<CaseSheetWrapper/>} />
                <Route path='auth/casesheet-survey/:templeteId/:userId' element={<CaseSheetSurveyLayout/>} />
                <Route path='auth/org-admin/*' element={<OrgAdminRouter/>} />
                <Route path='auth/su-admin/*' element={<SuperAdminRouter/>} />
                
            </Route>

        </Routes>
    )
}

export default PrivateRouter