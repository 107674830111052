import React from 'react'
import AppointmentLayout from './AppointmentLayout'
import { Route, Routes } from 'react-router-dom';
import BookAppointment from './BookAppointment';
import BookedAppointment from './BookedAppointment';

function AppointmentRouter() {
  return (
    <Routes>
            <Route element={<AppointmentLayout />} >
            <Route path='book-appointment' element={<BookAppointment />} />
            <Route path='booked-appointment' element={<BookedAppointment />} />
            <Route index element={<BookAppointment />} />
                </Route>
        </Routes>
  )
}

export default AppointmentRouter