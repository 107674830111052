import React from 'react'
import { Outlet } from 'react-router-dom'

function DoctorLayout() {
  return (
    <>
    {
      /*
 <div className='card p-3 border-radius'>
      
      <div className='d-flex justify-content-between'>
      <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>Doctor Module</div>

      </div>
      </div>


      */
    }
   
    
    <Outlet/>
    </>
  )
}

export default DoctorLayout;