import { createSlice } from '@reduxjs/toolkit';

export const GlobalState = createSlice({
    name: 'globalState',
    initialState: {
      radioGroupCss:"none",
      
    },
    reducers: {
      groupState: (state, action) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.radioGroupCss=action.payload;
        
       // localStorage.setItem('authData', JSON.stringify(action.payload.data));
        //state.value += 1
      },
      
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { groupState } = GlobalState.actions
  
  export default GlobalState.reducer;