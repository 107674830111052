export const RouteData = [
    {
        role: "op-rec",
        value: [{
            menuCategory: "Appointments",
            menus: [
                { uri: "/auth/bk/book-appointment", DisplayName: "Book Appointment" },
                { uri: "/auth/bk/booked-appointment", DisplayName: "Booked Appointment" }
            ]
        },
        {
            menuCategory: "Survey",
            menus: [
               
                { uri: "/auth/casesheet-survey", DisplayName: "Survey conductor" },
               
            ]
        }
        ]

    },
    {
        role: "re-operator",
        value: [
        {
            menuCategory: "survey",
            menus: [
               
                { uri: "/auth/casesheet-survey", DisplayName: "Survey conductor" },
               
            ]
        }
        ]

    },
    {
        role: "doctor",
        value: [{
            menuCategory: "Casesheet Module",
            menus: [
                { uri: "/auth/doc/doctor-setting", DisplayName: "Doctor setting" },
                { uri: "/auth/doc/create-pre-questions", DisplayName: "Manage Casesheet" },
               { uri: "/auth/doc/paitent-doc", DisplayName: "Casesheet" },
                
               
            ]
        },{
            menuCategory: "Appointments",
            menus: [
                { uri: "/auth/bk/book-appointment", DisplayName: "Book Appointment" },
                { uri: "/auth/bk/booked-appointment", DisplayName: "Booked Appointment" }
               
            ]
        }
        ]

    }
,
    {
        role: "admin",
        value: [{
            menuCategory: "Doctor",
            menus: [
                { uri: "/dashboard", DisplayName: "Dashboard" },
                { uri: "/book-appointment", DisplayName: "Book Appointment" }
            ]
        }
        ]

    },
    {
        role: "su-admin",
        value: [{
            menuCategory: "Doctor",
            menus: [
                { uri: "/auth/doc/doctor-setting", DisplayName: "Doctor setting" },
                { uri: "/auth/doc/create-pre-questions", DisplayName: "Pre QuestionCreater" },
                { uri: "/auth/casesheet-survey", DisplayName: "Survey conductor" },
                { uri: "/auth/doc/paitent-doc", DisplayName: "Casesheet" },
                { uri: "/auth/su-admin", DisplayName: "Super Admin" },
                { uri: "/auth/org-admin", DisplayName: "Org Admin" }
               
            ]
        },
        {
            menuCategory: "Appointments",
            menus: [
                { uri: "/auth/bk/book-appointment", DisplayName: "Book Appointment" },
                { uri: "/auth/bk/booked-appointment", DisplayName: "Booked Appointment" }
            ]
        }
        ]

    }

];

