import React, { useEffect } from 'react'
import { Link, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import * as service from './../../service/ApiService';
import { useState } from 'react';
import { appointmentStatus } from '../../Utils/MapConstant';
import * as Icon from 'react-bootstrap-icons';
function BookedAppointment() {

  const [doctor, setDoctor, date,setRefresh] = useOutletContext();
  const userId = useSelector((state) => state.auth.userId);
  const frontendCode = useSelector((state) => state.auth.frontDeskCode);
  const [appointmentList, setAppointmentList] = useState();
  const [isLoading, setIsLoading] = useState(true);
  //const [selectBg, setSelectBg] = useState("");

  console.log("BookedAppointment doctor::"+JSON.stringify(doctor))
  useEffect(() => {
    
    const getAppointmentHistory = async () => {
      const data = await service.getAppointments(userId, doctor.doctorDetails.p_id, date);
      setAppointmentList(data.data);
      setIsLoading(false)
    };
    getAppointmentHistory();
  }, [date]);

  async function changeStatus(events) {
    let status_Obj = {
      bookingId : 0,
      bookingStatus:""
    }
let eventObj =events.target.value;
let value= eventObj.split("-");
status_Obj.bookingId= value[1];
status_Obj.bookingStatus= value[0];
let currentStatus= value[2];
    const data = await service.changeAppointmentStatus(userId, status_Obj);
    appointmentStatus.map((dataas ,dataasindx)=>{
      document.getElementById(status_Obj.bookingId).classList.remove(cssMap.get(dataas.key) );
      if(dataas.optionValue===status_Obj.bookingStatus){
        document.getElementById(status_Obj.bookingId).classList.add(cssMap.get(dataas.key) );
      }
      setRefresh(data);
  })
  }
  const cssMap = new Map([
    ["B", "slot-booked"],
    ["Q", "slot-queued"],
    ["D", "slot-done"]

  ]);
  return (
    <div className='card p-3 border-radius'>
      <div className='d-flex justify-content-between'>
        <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>Booking Appointment for Dr. {doctor.doctorDetails.firstName + " " + doctor.doctorDetails.lastName}</div>
        <div> </div>
        <div>
        </div>
      </div>
      <div className='card-body'>

        {
          isLoading ? ("Loading") : (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Appointment Id</th>
                  <th scope="col">Paitent ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Slot</th>
                  <th scope="col">Casesheet Status</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  appointmentList.map((appointmentData, appointmentDataIdx) =>
                    <tr>
                      <th scope="row">{appointmentData.bookingId}</th>
                      <td>{appointmentData.paitentDetails.p_id }</td>
                      <td>{appointmentData.paitentDetails.firstName + " " + appointmentData.paitentDetails.lastName} </td>
                      <td>{appointmentData.paitentDetails.mobileNumber}</td>
                      <td>{appointmentData.slotTiming.startTiming + " - " + appointmentData.slotTiming.endTiming}</td>
                      <td>{appointmentData.isBasicDetailsFilled === 0 ? (<div className='text-warning'>Not Filled</div>) : (<div className='text-success'>Filled</div>)}</td>
                     
                      <td> 
                        <select id={appointmentData.bookingId} class={"p-2 fs-8 form-control form-select form-select-solid form-select-md font-size-13 height35 " + cssMap.get(appointmentData.bookingStatus) } name="language" onChange={changeStatus}>
                        {

                          appointmentStatus.map((appointmentStatusData, appointmentStatusIndx) =>

                            <>
                              {

                                appointmentData.bookingStatus == appointmentStatusData.key ?
                                  (<option value={appointmentStatusData.optionValue +"-"+appointmentData.bookingId} selected>{appointmentStatusData.value }  </option>) :
                                  (<option value={appointmentStatusData.optionValue +"-"+appointmentData.bookingId}>{appointmentStatusData.value }  </option>)
                              }
                            </>

                          )

                        }

                      </select>
                      </td>
                      <td><Icon.PencilSquare/>  <Icon.Trash3Fill/> 
                      <Link to={'/auth/casesheet-survey/'+doctor.doctorDetails.frontDesktempleteURL+'/'+appointmentData.paitentDetails.p_id}>Fill Data</Link>

                      </td>
                    </tr>
                  )
                }

              </tbody>
            </table>
          )
        }



      </div>
    </div>
  )
}

export default BookedAppointment