import React,{useEffect, useState} from 'react'
import DobInputComponent from './DobInputComponent';
import HeightInputComponent from './HeightInputComponent';
import WeightImputComponent from './WeightImputComponent';
import BmiValueDisplay from './BmiValueDisplay';

function RenderPredefineInputs(props) {
    const {inputData} =props;
    const [inputRender,setInputRender] =useState();

    const components =[
        {key:"dob" ,value:<DobInputComponent/>},
        {key:"height" ,value:<HeightInputComponent/>},
        {key:"weight" ,value:<WeightImputComponent/>},
        {key:"bmi" ,value:<BmiValueDisplay/>}
    ];

    useEffect(() => {
        const inputRenderComp =[];
        inputData.map(inputs=>{
            components.map(component =>{
                if(inputs.value == component.key){
                    inputRenderComp.push(component.value);
                }
            })
        })
        setInputRender(inputRenderComp);
    
      
    }, [inputData])
    

  return (
    <div>{inputRender}</div>
  )
}

export default RenderPredefineInputs