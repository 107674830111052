import React from 'react'

export default function BmiValueDisplay() {
  return (
    <div className='p-4'>
         
      <input type="text" className="form-control col-4" />
       
    </div>
  )
}
