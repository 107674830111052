import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux';
import * as service from './../../service/SurveyService';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from '../../Utils/MapConstant';
import * as popUp from '../../component/MessagePopup/MessagePopupComp';

function FrontDeskSetter() {
    const [isLoading, setIsLoading] = useState(true);
    const [fontDeskTemplete, setFontDeskTemplete] =useState();
    const [templates, setTemplates] = useState();
    const userId = useSelector((state) => state.auth.userId);
    useEffect(() => {
        const fetchMyAPI = async () => {
          const template = await service.getTempletes(userId);
          setTemplates(template.data);
          setIsLoading(false);
        }
        fetchMyAPI();
      }, [])

    function handleFontDeskTemplete(e){
        setFontDeskTemplete(e.target.value);
      }


      async function saveFrontDeskSetting(event) {
        let data= {userId : userId , templateId:fontDeskTemplete,templeteType:'frontDeskTemplete' };
        const templateData = await service.saveFrontDeskSetting(userId, data);
        if(!(templateData.ErrorCode != undefined)){
          popUp.MessagePopupComp('FrontDesk setting saved ',constant.Success);
          }else {
            popUp.MessagePopupComp('Error saving FrontDesk setting',constant.Error);
         }
      }

  return (
    <div>
        <div className='card p-2 m-2' >
<div class="form-group row pt-3">
    <label for="staticEmail" class="col-sm-2 col-form-label">Font desk Templete</label>
    <div class="col-sm-10">
    <select class="form-select" aria-label="Default select example" onChange={handleFontDeskTemplete} name="templateDoctorId">
        <option selected>Select Frontdesk Template</option>
        {
          isLoading ? ("Loading") : (
            templates.map((templete) =>
              <>{
                (templete.templeteType === 'TM' &&  templete.groupType=='frontdesk')&&
                <>
                  {
                    templete.default ? (<option value={templete.templeteId} selected>{templete.templeteName}</option>) :
                    (<option value={templete.templeteId}>{templete.templeteName}</option>)
                        
                  }
                </>
              }
              </>
            )
          )
        }
        
      </select>
     </div>
  </div>
  <div className='d-flex justify-content-end p-2'>
  <button type='submit' className="btn btn-primary rounded submit" onClick={saveFrontDeskSetting}>Save</button>

  </div>
  
</div>
    </div>
  )
}

export default FrontDeskSetter;