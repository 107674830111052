import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import clsx from 'clsx';
import * as service from '../service';
import {  useDispatch } from 'react-redux';
import { saveAuth} from './../../../reducer/authReducer';

export default function Login() {
  const dispatch = useDispatch()
  
  const initialValues = {
    email: '',
    password: '',
  }
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try{
        const data = await service.login(values.email,values.password);
        dispatch(saveAuth(data));
        navigate('/auth/dashboard');
      }catch(error){
        setStatus('The login details are incorrect');
        setSubmitting(false);
        setLoading(false);
      }

    }
  })


  return (
    <>
    <h3 className="text-center mb-4">Have an account?</h3>
    {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ""
      )}
      
						<form className="login-form"  onSubmit={formik.handleSubmit}>
		      		<div className="form-group">
              <div className="mb-3">
  
  <input type="email"   placeholder="name@example.com"
  {...formik.getFieldProps('email')}
  className={clsx(
    'form-control',
    {'is-invalid': formik.touched.email && formik.errors.email},
    {
      'is-valid': formik.touched.email && !formik.errors.email,
    }
  )}
 
  name='email'
  autoComplete='off'
  />
</div>
		      		</div>
	            <div className="form-group">
              <div className="mb-3">
  
  <input type="password"  placeholder="Password"
  {...formik.getFieldProps('password')}
  className={clsx(
    'form-control',
    {'is-invalid': formik.touched.password && formik.errors.password},
    {
      'is-valid': formik.touched.password && !formik.errors.password,
    }
  )}
  />
</div>
	            </div>
	            <div className="form-group">
	            	<div >
	            		<label >Dont remember your password? </label>
                  <Link to='/auth/forget-password' className='link ms-1'>Forgot Password</Link>
								
								</div>
								
	            </div>
	            <div className="form-group">
	            	
                <div className='d-grid mb-10'>
        <button
          type='submit'
         
          className="btn btn-primary rounded submit"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

	            </div>
	          </form>
            </>
  )
}
