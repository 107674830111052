import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Button } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import * as service from './../../service/ApiService';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function CaseSheetWrapper() {

  const data = [
    { pId: "P001", name: "shantan", mobile: "8776576576" },
    { pId: "P002", name: "kiran", mobile: "8776576523" },
    { pId: "P003", name: "shantan1", mobile: "8776233576" },
    { pId: "P004", name: "shanta2", mobile: "87765775476" },
    { pId: "P005", name: "shanta3", mobile: "87765763436" },
    { pId: "P006", name: "shanta4", mobile: "877657663" },
    { pId: "P007", name: "shantan5", mobile: "87765763356" }

  ]
  const [doctor, setDoctor] = useState();
  const [dataState, setDataState] = useState([]);
  const [userInput, setuserInput] = useState("");
  const [tempData, setTempData] = useState(dataState);
  const [date, setDate] = useState();
  const [doctorList, setDoctorList] = useState([]);
  const userId = useSelector((state) => state.auth.userId);
  const [appointmentList, setAppointmentList] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [casesheet, setCasesheet] = useState();
  useEffect(() => {
    const getUsers = async () => {
      const data = await service.getDoctors(userId);
      setDoctorList(data.data);
      setDate(moment().format('DD-MM-yyyy'))
    };
    getUsers();
  }, []);

  useEffect(() => {
    const getAppointmentHistory = async () => {
      const data = await service.getAppointments(userId, doctor.p_id, date);
      setDataState(data.data);
      setIsLoading(false)
      setTempData(data.data)
    };
   if (doctor != undefined){
    getAppointmentHistory();
    setCasesheet(doctor.caseSheetTempleteURL)
   }
    
  }, [doctor, date]);


  useEffect(() => {
    const filteredSuggestions = dataState.filter(suggestionObj);
    setTempData(filteredSuggestions);

    if (userInput.length == 0) {
      setTempData(dataState);
    }

  }, [userInput])


  const searchHandler = () => {
    }

  const doctorValue = (e) => {
    doctorList.map ((doc)=>{
      if(doc.p_id === e.currentTarget.value){
        console.log("doc ::"+JSON.stringify(doc))
        setDoctor(doc);
      }
    })
   
  }
  const handleOnChange = (event) => {
    const userInput = event.currentTarget.value;
    setuserInput(userInput);
  }

  function suggestionObj(data) {
    if (data.paitentDetails.mobileNumber.toLowerCase().indexOf(userInput.toLowerCase()) > -1 || data.paitentDetails.p_id.toLowerCase().indexOf(userInput.toLowerCase()) > -1) {
      return data;
    }
  }
  return (
    <>
      <div className='card mt-2 '>
        <div className='d-flex justify-content-between mt-4 mx-4'>
          <div className="form-group">
            <div className="mb-3">
              <select class="p-2 fs-8 form-control form-select form-select-solid form-select-md " name="language" onChange={doctorValue}>
                <option value="">Select a Doctor</option>
                {doctorList && doctorList.length > 0 && doctorList.map((doctor, index) => (
                  <option key={index} value={doctor.p_id}>Dr. {doctor.firstName + " " + doctor.lastName}</option>
                ))}
              </select>
            </div>
          </div>
          <div >
            <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                showDropdowns: true,
                startDate: new Date(),
                minYear: 1901,
                maxYear: parseInt(moment().format('YYYY'), 10),
              }}
              onCallback={(start) => {
                setDate(start.format('DD-MM-yyyy'))
              }}
            >
              <input type="text" className="form-control col-4" />
            </DateRangePicker>
          </div>
        </div>
       
      </div>
      <div className='card mt-2 p-4'>
              
       
            {isLoading ? (
              <div className="icon d-flex align-items-center justify-content-center">
                <span className='fs-6 fw-bolder text-gray-900'>Please select doctor</span></div>
            ) : (
              <>
              <div className='d-flex justify-content-between '>
          <div class="form-group floating-label wid100 m-2 mx-4">
            <input id="textarea" class="form-control" placeholder=" " name="Question"
              onChange={(e) => setuserInput(e.target.value.toLowerCase())}
            />
            <label htmlFor="text">Search</label>
          </div>
          <div className='m-2 mx-4'>
            <Button variant="secondary" onClick={searchHandler}>
              Search
            </Button>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Paitent Id</th>
              <th scope="col">Name</th>
              <th scope="col">Mobile</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          {
              tempData.map((itrData) =>
                <>
                
                 
          <tbody>
                  <tr>
                    <th scope="row">{itrData.paitentDetails.p_id}</th>
                    <td>{itrData.paitentDetails.firstName + " " + itrData.paitentDetails.lastName}</td>
                    <td>{itrData.paitentDetails.mobileNumber}</td>
                    <td>{itrData.isCasesheetFilled === 0 ? (<div className='text-warning'>Not Filled</div>) : (<div className='text-success'>Filled</div>)}</td>
                     
                    <td>
                      <Link className='btn secondary' to={'/auth/casesheet-survey/'+casesheet+"/" + itrData.paitentDetails.p_id}>
                        Fill Casesheet
                      </Link>
                    </td>
                  </tr>
                  </tbody>
        
                </>
                
              )
              
        }
        </table>
              </>
            )
            }

      </div>
    </>
  )
}

export default CaseSheetWrapper