import React from 'react'
import { Link } from 'react-router-dom';
import './sidebar.scss';
export default function MenuItem(props) {
    const {uri,displayName} =props;

  
  return (
    
    <li class="menu-item">
        <Link to={uri} aria-expanded="false" class=" ">{displayName}</Link>
        </li>
  )
}
