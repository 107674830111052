import React from 'react'

export const PrintQuestionComponent=React.forwardRef((props, ref) => {

    const {questions} =props;
   
  return (
    <div ref={ref} className='icon d-flex align-items-center justify-content-center top-pad-200'>
        
    <ul>
        {
            questions.map((question) =>
                <>{ question.printMsg != null && question.printable ==true && <><li>{question.printMsg.replace("(answer-data)",question.answer)}</li></> }</>
            )
        }
      </ul> 
      </div>
  )
});

