import React,{useEffect, useState} from 'react'
import './tinyCard.css';
import * as service from './../../service/SurveyService';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

function TinyCard() {
    const userIdSession = useSelector((state) => state.auth.userId);
    const role = useSelector((state) => state.auth.role);
    const [appointmentStats,setAppointmentStats] = useState();
    const [totalPaitentCount,setTotalPaitentCount] = useState(0);
    const [completed,setCompleted] = useState(0);
    const [bookedCount,setBookedCount] = useState(0);
    const [queued,setQueued] = useState(0);
    useEffect(() => {
       
        const fetchMyAPI = async () => {
            const template = await service.getAppointmentStats(userIdSession, moment().format('DD-MM-yyyy'));
           setAppointmentStats(template.data)
           let countData = 0;
           template.data.map((data) =>{
            countData = countData+data.count
            if(data.status=='booked'){
                setBookedCount(data.count);
              }else if(data.status=='done'){
                setCompleted (data.count);
              }else if(data.status=='queued'){
                setQueued(data.count);
              }
           }
           )
           setTotalPaitentCount(countData);
        }
       
        fetchMyAPI();
    },[])
    


  return (
    <div>
       
    <div class="row">
        <div class="col-md-6 col-xl-6">
            <div class="card bg-c-blue order-card">
                <div class="card-block">
                    <h6 class="m-b-20">Total Patient</h6>
                    <h2 class="text-right"><i class="fa fa-cart-plus f-left"></i><span>{totalPaitentCount}</span>
                    
                    </h2>
                    
                    <p class="m-b-0">Completed Review<span class="f-right">{completed}</span></p>
                    {
                        role == 'doctor' &&<div className='d-flex justify-content-between'>
                        <Link to="/auth/doc/paitent-doc" type='submit' className="btn btn-primary rounded submit p-2 px-4 no-margin">Casesheet</Link>
                         
                        </div>
                    }
                   
                    
                </div>
            </div>
        </div>
        
        <div class="col-md-6 col-xl-6">
            <div class="card bg-c-green order-card">
                <div class="card-block">
                    <h6 class="m-b-20">Waiting Patient</h6>
                    <h2 class="text-right"><i class="fa fa-rocket f-left"></i><span>{queued}</span></h2>
                    <p class="m-b-0">Pending Patient<span class="f-right">{bookedCount}</span></p>
                    {
                        role == 'doctor' &&<div className='d-flex justify-content-between'>
                        <Link to="/auth/bk/booked-appointment" className="btn btn-primary rounded submit p-2 px-4 no-margin">View Data</Link>
                         
                        </div>
                    }
                </div>
            </div>
        
        {
            /*
        <div class="col-md-4 col-xl-3">
            <div class="card bg-c-yellow order-card">
                <div class="card-block">
                    <h6 class="m-b-20">Orders Received</h6>
                    <h2 class="text-right"><i class="fa fa-refresh f-left"></i><span>486</span></h2>
                    <p class="m-b-0">Completed Orders<span class="f-right">351</span></p>
                </div>
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3">
            <div class="card bg-c-pink order-card">
                <div class="card-block">
                    <h6 class="m-b-20">Orders Received</h6>
                    <h2 class="text-right"><i class="fa fa-credit-card f-left"></i><span>486</span></h2>
                    <p class="m-b-0">Completed Orders<span class="f-right">351</span></p>
                </div>
            </div>
        </div>
        */
        }
	</div>
</div>
    </div>
  )
}

export default TinyCard