import React,{useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';

function DobInputComponent() {
  const [date, setDate] =useState();
  console.log("In date ::"+date)
  return (
    <div className='p-4'>
      <div className='row'>
      <div className='col-6'>
      <div className="TextField-without-border-radius">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
    
      <DatePicker label="Date of Birth" defaultValue={dayjs(new Date())}   
                                    slotProps={{ textField: { size: 'small' } }}
                                    />
      
     </LocalizationProvider>
     </div>
    </div>
    </div>
    </div>
  )
}

export default DobInputComponent