import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import Creatable from 'react-select/creatable';
import * as service from './../../service/SurveyService';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';

function PreQuestionCreater(props) {

  const { questionList, setQuestionList, question, groups, setGroups, onHide, groupDetail, setGroupDetail, unqGroup, setUnqGroup, editQuestion, editQuestionId } = props;
  const [optionList, setOptionList] = useState([]);
  const [buttonType, setButtonType] = useState('addQuestion');
  const [options, setOptions] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [optionType, setOptionType] = useState('text');
  const [groupQuestion, setGroupQuestion] = useState();
  const [buttonText, setButtonText] = useState('Add Question');
  const [option, setOption] = useState([]);
  const [editQuestionData, setEditQuestionData] = useState();
  const userId = useSelector((state) => state.auth.userId);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    addOption()
  };
  const initialValues = {
    Question: '',
    QuestionOptionType: '',
    toggleBtn: '',
    qGroup: [],
    groupData: ""
  }

  const bookAppointmentSchema = Yup.object().shape({
    Question: Yup.string()
      .required("Question is required ")
      .min(3, 'Minimum 3 symbols'),
    QuestionOptionType: Yup.string()
      .required().oneOf(['text', 'textArea', 'radio', 'checkbox', 'dropdown'], "Please select type of question ")
  })


  useEffect(() => {
    const fetchData = async () => {

      const template = await service.getTempletes(userId);
      const uniqueTags = [];
      template.data.map((data) => {
        if (data.templeteType === 'TG') {
          uniqueTags.push({ 'value': data.templeteId, 'label': data.templeteName });
        }
      })
      setOption(uniqueTags);
    }
    fetchData();
    if (editQuestion != undefined || editQuestion == true) {
      setButtonType('editQuestion');
      questionList.map(question => {

        if (editQuestionId == question.questionId) {
          setButtonText('Edit Question' );
          setEditQuestionData(question);
          setOptionType(question.questionType);
          initialValues.Question = question.question;
          initialValues.QuestionOptionType = question.questionType;
          initialValues.qGroup = question.group;
          setGroupData(question.group)
          if (question.questionType == 'radio' || question.questionType === 'checkbox') {
            setIsChecked(true);
            setGroupData(question.group);
            question.optionsData.map(optionValue => {
              if (!optionList.includes(optionValue)) {
                optionList.push(optionValue);
              }
            })
            setOptions(optionList);
          }
        }
      })
      //setEditQuestionData
    }
  },[buttonType])


  function onChangeFunction(event) {
    setOptionType(event.target.value);
    formik.setFieldValue("QuestionOptionType", event.target.value);
    setOptionList([]);
  }


  function removeOption(optionToValue, optionType) {
    var index = optionList.indexOf(optionToValue)
    if (index !== -1) {
      optionList.splice(index, 1);
    }
    addOption();
  }

  function addOption(event) {
    setButtonType("AddEvent")
    let valueText = document.getElementById("inputValue").value;
    if (valueText !== undefined && valueText.length > 0 && optionList.indexOf(valueText) === -1) {
      optionList.push(valueText);
      groupData.push({ optionLabel: valueText, group: [] })
    }
    setOptions(optionList)
    document.getElementById("inputValue").value = "";
    ///event.preventDefault();
  }

  const formik = useFormik({
    initialValues,
    validationSchema: bookAppointmentSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      try {
        if (buttonType === 'addQuestion') {
          if (groupDetail !== undefined) {
            let questionObj = { questionId: questionList.length + 1, question: values.Question, questionType: values.QuestionOptionType, optionsData: options, group: values.qGroup, privateQuestion: false, printable: false, printMsg: values.Question + " : " + "(answer-data)" }
            const dt = JSON.parse(groupDetail);
            const gd = unqGroup.map((data) => {
              if (data.value == dt.value && data.label === dt.label) {
                data.questionList.push(questionObj)
              }
            })
          } else {
            if (questionList.length === 0) {
              let questionObj = [{ questionId: questionList.length + 1, question: values.Question, questionType: values.QuestionOptionType, optionsData: options, group: values.qGroup, privateQuestion: false, printable: false, printMsg: values.Question + " : " + "(answer-data)" }]
              setQuestionList(
                questionObj
              );
            } else {
              let isMatch = true;
              questionList.map((qData, qindx) => {
                if (values.Question === qData.question) {
                  isMatch = false;
                  let spliceElement = questionList.splice(qindx, 1);
                  let questionObj = { questionId: questionList.length + 1, question: values.Question, questionType: values.QuestionOptionType, optionsData: options, group: values.qGroup, privateQuestion: false, printable: false, printMsg: values.Question + " : " + "(answer-data)" }
                  setQuestionList(
                    (questionList) => [
                      ...questionList,
                      questionObj
                    ]
                  );
                }
              })
              if (isMatch === true) {
                let questionObj = { questionId: questionList.length + 1, question: values.Question, questionType: values.QuestionOptionType, optionsData: options, group: values.qGroup, privateQuestion: false, printable: false, printMsg: values.Question + " : " + "(answer-data)" }
                setQuestionList(
                  (questionList) => [
                    ...questionList,
                    questionObj
                  ]
                );
              }
            }
          }
          setIsChecked(false);
          setOptionList([]);
          setOptions(undefined);
          resetForm();
        } else if ( buttonType =='editQuestion') {

         questionList.map( (question, ind) => {
          if(editQuestionId == question.questionId){
            let quwestionPrintMsg = "";
            if(question.printMsg == null){
              quwestionPrintMsg =values.Question + " : " + "(answer-data)"
            }else {
              quwestionPrintMsg = question.printMsg ;
            }
            let questionObj = { questionId: editQuestionId, question: values.Question, questionType: values.QuestionOptionType, optionsData: options, group: values.qGroup, privateQuestion: question.privateQuestion, printable: question.printable, printMsg:quwestionPrintMsg  }
            questionList[ind] = questionObj; 
          }
         setQuestionList([]);
         setQuestionList(questionList);
         })
         setIsChecked(false);
          setOptionList([]);
          setOptions(undefined);
          resetForm(); 
        } else {
        }
        //formik.resetForm();
        //QuestionOptionType
        // document.getElementById("QuestionOptionType").value="";
      } catch (error) {
      }
    }
  })

  const settingButtonType =(buttonType) =>{
    if(buttonType == 'Add Question'){
      setButtonType('addQuestion');
    }else if (buttonType == 'Edit Question'){
      setButtonType('editQuestion');
    }
  }

  return (
    <div>

      <form className="login-form" onSubmit={formik.handleSubmit}>
        <div class="col-lg-12 col-xl-12 pt-4">
          <div class="d-flex justify-content-end">
            {
              ((optionType === 'radio') || (optionType === 'checkbox')) && (<div>
                Group question bases on option  <label class="switch">
                  <input type="checkbox" name='toggleBtn' checked={isChecked} onChange={handleOnChange} />
                  <span class="slider round"></span>
                </label>
              </div>)
            }
          </div>
          <div class="form-group floating-label">
            <input id="textarea" class="form-control" placeholder=" " name="Question" initialValue={initialValues.Question}
              {...formik.getFieldProps('Question')}
              className={clsx(
                'form-control',
                { 'is-invalid': formik.touched.Question && formik.errors.Question },
                {
                  'is-valid': formik.touched.Question && !formik.errors.Question,
                }
              )}
            />
            <label htmlFor="text">Question</label>
            {
              formik.errors.Question && (<div style={{ color: "red" }}>{formik.errors.Question}</div>)
            }
          </div>
        </div>
        <div class="col-lg-12 col-xl-12 pt-4">
          <div class="row">
            {
              editQuestion ? (<>
                {
                  editQuestionData != undefined &&
                  <div class="col">
                    <div className="form-check form-check-inline">
                      <input className={"form-check-input font-14"}
                        name="QuestionOptionType"
                        type="radio"
                        value="text"
                        id="text"
                        onChange={onChangeFunction}
                        defaultChecked={editQuestionData.questionType === 'text'}
                      />
                      <label className="form-check-label font-14" htmlFor="inlineRadio1">Text Input</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className={"form-check-input font-14"}
                        name="QuestionOptionType"
                        type="radio"
                        value="textArea"
                        id="textArea"
                        onChange={onChangeFunction}
                        defaultChecked={editQuestionData.questionType === 'textArea'}
                      />
                      <label className="form-check-label font-14" htmlFor="inlineRadio2">Text Area Input</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className={"form-check-input font-14"}
                        name="QuestionOptionType"
                        type="radio"
                        value="radio"
                        id="radio"
                        onChange={onChangeFunction}
                        defaultChecked={editQuestionData.questionType === 'radio'}
                      />
                      <label className="form-check-label font-14" htmlFor="inlineRadio2">Radio</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className={"form-check-input font-14"}
                        name="QuestionOptionType"
                        type="radio"
                        value="checkbox"
                        id="checkbox"
                        onChange={onChangeFunction}
                        defaultChecked={editQuestionData.questionType === 'checkbox'}
                      />
                      <label className="form-check-label font-14" htmlFor="inlineRadio2">Checkbox</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className={"form-check-input font-14"}
                        name="QuestionOptionType"
                        type="radio"
                        value="dropdown"
                        id="dropdown"
                        onChange={onChangeFunction}
                        defaultChecked={editQuestionData.questionType === 'dropdown'}
                      />
                      <label className="form-check-label font-14" htmlFor="inlineRadio2">Dropdown</label>
                    </div>
                    {
                      formik.errors.QuestionOptionType && (<div style={{ color: "red" }}>{formik.errors.QuestionOptionType}</div>)
                    }
                  </div>
                }</>) : (<>
                  <div class="col">
                    <div className="form-check form-check-inline">
                      <input className={"form-check-input font-14"}
                        name="QuestionOptionType"
                        type="radio"
                        value="text"
                        id="text"
                        onChange={onChangeFunction}
                      />
                      <label className="form-check-label font-14" htmlFor="inlineRadio1">Text Input</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className={"form-check-input font-14"}
                        name="QuestionOptionType"
                        type="radio"
                        value="textArea"
                        id="textArea"
                        onChange={onChangeFunction}
                      />
                      <label className="form-check-label font-14" htmlFor="inlineRadio2">Text Area Input</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className={"form-check-input font-14"}
                        name="QuestionOptionType"
                        type="radio"
                        value="radio"
                        id="radio"
                        onChange={onChangeFunction}
                      />
                      <label className="form-check-label font-14" htmlFor="inlineRadio2">Radio</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className={"form-check-input font-14"}
                        name="QuestionOptionType"
                        type="radio"
                        value="checkbox"
                        id="checkbox"
                        onChange={onChangeFunction}
                      />
                      <label className="form-check-label font-14" htmlFor="inlineRadio2">Checkbox</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className={"form-check-input font-14"}
                        name="QuestionOptionType"
                        type="radio"
                        value="dropdown"
                        id="dropdown"
                        onChange={onChangeFunction}
                      />
                      <label className="form-check-label font-14" htmlFor="inlineRadio2">Dropdown</label>
                    </div>
                    {
                      formik.errors.QuestionOptionType && (<div style={{ color: "red" }}>{formik.errors.QuestionOptionType}</div>)
                    }
                  </div></>)
            }


          </div>
        </div>
        <div >
          <div className='row pt-4'>
            <div className='col-12'>
              <div className="form-check form-check-inline ">
                {
                  optionType === 'text' && (
                    <div >
                      <p>Text with max 50 charater</p>
                      <div class="form-group floating-label">
                        <input id="inputValue" class="form-control" placeholder=" " name="optionExample" className={'form-control'}
                        />
                        <label htmlFor="text">Example Input name </label>
                      </div>

                    </div>
                  )
                }
                {
                  optionType === 'textArea' && (
                    <div >
                      <p>Text with more then 50 charater</p>
                      <div class="form-group floating-label">
                        <textarea id="inputValueArea" className="form-control h100" rows="4" cols="50" type="textarea" placeholder=" " name="optionExample"
                        />
                        <label htmlFor="text">Example Text area </label>
                      </div>

                    </div>
                  )
                }
                {
                  optionType === 'radio' && (
                    <div className='d-flex justify-content-between'>
                      <div class="form-group floating-label">
                        <input id="inputValue" class="form-control" placeholder=" " name="optionType" className={'form-control'}
                        />
                        <label htmlFor="text">Option name </label>
                      </div>
                      <div className='px-4'>
                        <button type='submit' className="btn btn-primary rounded submit px-4" onClick={e=>addOption(e)}> Add </button>
                      </div>
                    </div>
                  )
                }
                {
                  optionType === 'checkbox' && (
                    <div className='d-flex justify-content-between'>
                      <div class="form-group floating-label">
                        <input id="inputValue" class="form-control" placeholder=" " name="optionType" className={'form-control'}
                        />
                        <label htmlFor="text">Option name </label>
                      </div>
                      <div className='px-4'>
                        <button type='submit' className="btn btn-primary rounded submit px-4" onClick={addOption}> Add </button>
                      </div>
                    </div>
                  )
                }
                {
                  optionType === 'dropdown' && (
                    <div className='d-flex justify-content-between'>
                      <div class="form-group floating-label">
                        <input id="inputValue" class="form-control" placeholder=" " name="optionType" className={'form-control'}
                        />
                        <label htmlFor="text">Option name </label>
                      </div>
                      <div className='px-4'>
                        <button type='submit' className="btn btn-primary rounded submit px-4" onClick={addOption}> Add </button>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className='col-12 pt-4'>
              <div className='row example-container' >
                {isChecked === true && (
                  optionList.map((data, indx) =>
                    <div >
                      <DisplayInputComponent option={option} showgroup={'visible'} indx={indx} formik={formik} optionType={optionType} optionValue={data} removeOption={removeOption} optionList={optionList} groupData={groupData} setGroupData={setGroupData} editQuestion={editQuestion} options={options}/>
                    </div>
                  )
                )
                }
                {isChecked === false && (
                  optionList.map((data, indx) =>
                    <div >
                      <DisplayInputComponent option={option} showgroup={'none'} formik={formik} optionType={optionType} optionValue={data} removeOption={removeOption} optionList={optionList} groupData={groupData} setGroupData={setGroupData} editQuestion={editQuestion} options={options}/>
                    </div>
                  )
                )
                }
                <button type='submit' className="btn btn-primary rounded submit px-4 mt-2" onClick={() => { settingButtonType(buttonText)  }}>{buttonText }</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

const DisplayInputComponent = (props) => {

  const { optionType, optionValue, removeOption, optionList, showgroup, formik, indx, groupData, setGroupData, option, editQuestion ,options} = props;
  const [optionListData, setOptionListData] = useState([]);
  function deleteOption(el, optionType) {
    removeOption(el, optionType);
  }
  useEffect(() => {
    if (editQuestion == true) {
      let data = [];
      groupData.map(option => {
        //setOptionListData(...optionListData,option);
        if (option.optionLabel == optionValue) {
          option.group.map(value => {
            data.push({ "value": value.value, "label": value.label })
          })
          // data= option.group;
        }

      })

          options.map(value => {
             let boolValue = false;
              groupData.map(data=>{
                if(data.optionLabel == value){
                  boolValue = true;
                }
              })
              if(boolValue==false){
                groupData.push({"questionId":60,"optionLabel":value,"group":[]});
              }
          })
      setOptionListData(data);
      setGroupData(groupData);
    }

  }, [])
  function queGroup(event, optionName) {
    groupData.map((data, indx) => {
      if (data.optionLabel === optionName) {
        let grp = groupData.splice(indx, 1)[0];
        grp.group = event;
        groupData.push(grp)
        setGroupData(groupData);
      }
    })
    formik.setFieldValue('qGroup', groupData);
  }
  if (optionType === 'radio' || optionType === 'checkbox') {
    return (
      <div className='d-flex justify-content-between'>
        <div className="form-check form-check-inline">
          <input className={"form-check-input font-14"}
            name="genderV"
            type="radio"
            value="text"
            id="text"
          />
          <label className="form-check-label font-14" htmlFor="inlineRadio1"> {optionValue}</label>
        </div>

        <div className='d-flex justify-content-between'>

          {
            showgroup === 'visible' && (<>

              {
                optionListData.length > 0 ? (<>    <Creatable key={showgroup + "edit"}
                  styles={'form-select goup-dw pt-2'}
                  closeMenuOnSelect={false}
                  isMulti
                  id={indx}
                  options={option}
                  defaultValue={optionListData}
                  onChange={(e) => queGroup(e, optionValue)}
                /></>) : (
                  <>
                    <Creatable key={showgroup + "nonedit"}
                      styles={'form-select goup-dw pt-2'}
                      closeMenuOnSelect={false}
                      isMulti
                      id={indx}
                      options={option}
                      onChange={(e) => queGroup(e, optionValue)}
                    />
                  </>
                )
              }
            </>

            )
          }
          <button className='noStyle' onClick={() => deleteOption(optionValue, optionType)} value={optionValue}> <Icon.Trash3Fill /></button>
        </div>
      </div>
    )
  } else if (optionType === 'dropdown') {
    return (
      <div>
        <div className='d-flex justify-content-between '>
          <div className="form-check form-check-inline">
            <label className="form-check-label font-14" htmlFor="inlineRadio1"> {optionValue}</label>
          </div>
          <div >
            <button className='noStyle' onClick={() => deleteOption(optionValue, optionType)} value={optionValue}> <Icon.Trash3Fill /></button>
          </div>
        </div>
      </div>
    )
  }
}

export default PreQuestionCreater