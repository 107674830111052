import React from 'react'
import QuestionCard from '../../component/questionDisplaycard/QuestionCard';

function GroupDisplay(props) {

    const {groupData,groupMetaData,handleChange} =props;
   return (
    <>
     {
        groupMetaData.map((data) =>
        data.label === groupData.label && data.questionList !== null &&
        <>
        {
            data.questionList.map((question,indx) =>
            <>
            <QuestionCard question={question} key={"q"+indx}  handleChange={handleChange} index ={question.questionId}/>
                </>
            )
        }
            
        </>

        )
    }
    </>
   
   
  )
}

export default GroupDisplay