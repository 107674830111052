import React from 'react'
import HeaderWrapper from '../../layout/header/HeaderWrapper'
import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import * as service from './../../service/SurveyService';
import * as userservice from './../../service/ApiService';
import QuestionCard from '../../component/questionDisplaycard/QuestionCard';
import GroupDisplay from '../PreQuestionModule/GroupDisplay';
import * as ReactDOM from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from '../../store/store';
import * as constant from '../../Utils/MapConstant';
import * as popUp from '../../component/MessagePopup/MessagePopupComp';

function CaseSheetSurveyLayout() {
    const params = useParams();
    const [templeteId, setTempleteId] = useState();
    const [userId, setUserId] = useState();
    const [templateData, setTemplateData] = useState();
    const [isLoading, setIsLoading] = useState();
    const [isUserLoading, setIsUserLoading] = useState(false);
    const [questionList, setQuestionList] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [groupMetaData, setGroupMetaData] = useState([]);
    const [groupComp, setGroupComp] = useState();
    const [data, setData] = useState([]);
    const [answer, setAnswer] = useState([]);
    const [pUser, setPUser] = useState();
    const [groupType, setGroupType] = useState();
    const userIdSession = useSelector((state) => state.auth.userId);
    const [buttonLoading, setButtonLoading] = useState(false);

console.log("CaseSheetSurveyLayout questionList ::"+JSON.stringify(questionList))
    useEffect(() => {
        setTempleteId(params.templeteId);
        setUserId(params.userId);
        const newData = [];
        console.log("params.userId ::"+params.userId)
        const fetchMyAPI = async () => {
            const template = await service.getTempleteByUrl(params.userId, params.templeteId,params.userId);
            setTemplateData(template.data.templeteData);
            setQuestionList(template.data.mainTemplate);
            setGroupData(template.data.templeteData.metaData.data);
            setGroupMetaData(template.data.groupData);
            setGroupType(template.data.templeteData.groupType);
            template.data.mainTemplate.map((data) =>
                newData.push({ id: data.questionId, value: "" })
            )
            setData(newData);
            setAnswer(newData);
            setIsLoading(false);
        }
        const fetchUserAPI = async () => {
            const user = await userservice.getDetailsById(userIdSession, params.userId);
            setPUser(user.data);
            setIsUserLoading(true);
        }

        fetchMyAPI();
        fetchUserAPI();
    }, [])
    const handleSubmit = async () => {


        setButtonLoading(true);
        let questionDataAnswer = [];
        answer.map((questionData) => {
            if (questionData.value != "") {
                questionDataAnswer.push({ "id": questionData.id, "value": questionData.value.toString() });
            } else {
                questionDataAnswer.push({ "id": questionData.id, "value": questionData.value });
            }
        }
        )


        let answerData = { pId: pUser.p_id, surveyAnswer: questionDataAnswer, formType: groupType };
        const template = await service.postSurveyAnswer(userId, answerData);
        setButtonLoading(false);
        popUp.MessagePopupComp(groupType + ' Template Updated successfully for Paitent Id '+ pUser.p_id,constant.Success);
       }
    const handleChange = (field, value, index, type) => {
        let checkBokData;

        let ind = answer.findIndex(
            entry => entry.id === index
        )
        if (ind === -1) {
            answer.push({ "id": index, "value": value });
        } else {
            answer.map((ansData) => {
                if (ansData.id == index) {
                    if (type === 'checkbox') {
                        if (ansData.value.length > 0) {
                            let arrValue = ansData.value;
                            let index = ansData.value.indexOf(value);
                            if (index > -1) {
                                arrValue.splice(index, 1);
                                ansData.value = arrValue;
                            } else {
                                arrValue.push(value);
                                ansData.value = arrValue;
                            }
                            checkBokData = ansData.value;
                        } else {
                            let arrValue = [];
                            arrValue.push(value);
                            ansData.value = arrValue;
                        }
                        checkBokData = ansData.value;
                    } else {
                        ansData.value = value;
                    }
                }
            }
            )
            setAnswer(answer);
        }
        // Display group data on click of options 
        let groupComptemp = [];
        let data= [];
        let ids=new Set();
        groupData.map((groupData, indexgd) => {
            if (groupData.questionId == index) {
                if (type == 'checkbox') {
                    checkBokData.map((boxvalue) => {
                        if (groupData.optionLabel === boxvalue) {
                            groupData.group.map((groupNumData) => {
                            if(groupComptemp.length >0){
                                groupComptemp.map((checkduplicate)=>{
                                if(ids.has(groupNumData.value) ){
                                }else {
                                 data.push(<GroupDisplay groupData={groupNumData} key={index} groupMetaData={groupMetaData} handleChange={handleChange} />)
                                 ids.add(groupNumData.value );
                                }
                                 })
                            }else {
                                data.push(<GroupDisplay groupData={groupNumData} key={index} groupMetaData={groupMetaData} handleChange={handleChange} />)
                                ids.add(groupNumData.value );
                            }
                                groupComptemp=data 
                            }
                            )
                        }
                    })
                } else {
                    if (groupData.optionLabel === value) {
                        groupData.group.map((groupNumData) => {
                            groupComptemp.push(
                                <GroupDisplay groupData={groupNumData} key={index} groupMetaData={groupMetaData} handleChange={handleChange} />
                            )
                        }
                        )
                    }
                }

            }
        })
        ReactDOM.createRoot(
            document.getElementById(index + "" + "w")
        ).render(
            <Provider store={store}>
                    {groupComptemp}
            </Provider>
            
        );
    }
    return (
        <>
            <div className='card p-4 mt-4'>
                <div className='row'>
                    {
                        isUserLoading ? (<>
                            <div className='col-2'>
                                <b>Paitent Id :</b> {pUser.p_id}
                            </div>
                            <div className='col-2'>
                                <b> name :</b>{pUser.firstName + " " + pUser.middleName}
                            </div>
                            <div className='col-2'>
                                <b> Mobile : </b>{pUser.mobileNumber + " "}

                            </div>
                            <div className='col-6'>
                                <b> Email :</b> {" " + pUser.email}
                            </div>


                        </>) : (<>
                            Loading
                        </>)
                    }

                </div>
            </div>
            <div className=''>
                {
                    isLoading ? ("Loading") : (<>
                        {
                            templateData !== undefined ? (<>
                                {questionList &&
                                    questionList.map((question, idx) =>
                                        <>
                                            {
                                                question.privateQuestion !== true && <>
                                                    <QuestionCard question={question} setQuestionList={setQuestionList} questionList={questionList} index={question.questionId} handleChange={handleChange} formEditable={false} />
                                                    {
                                                        groupData.map((groupData, index) =>
                                                            groupData.questionId == question.questionId &&
                                                            <div id={question.questionId + "" + "w"} className="pad-side-100"></div>
                                                        )
                                                    }
                                                </>
                                            }
                                        </>
                                    )
                                }
                            </>) : (<> Casesheet not configured. Please Contact your Admin </>)
                        }
                        <div className='d-flex justify-content-between pt-2'>
                            <div>
                            </div>
                            <div>
                                <button type='submit' className="btn btn-primary rounded submit p-2 px-4" onClick={handleSubmit} disabled={buttonLoading}>
                                    {!buttonLoading && <span className='indicator-label'>Save</span>}
                                    {buttonLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </>)
                }
            </div>
        </>

    )
}



export default CaseSheetSurveyLayout