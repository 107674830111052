import React,{useRef} from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CreatableSelect from 'react-select/creatable';
import PreQuestionCreater from '../../module/PreQuestionModule/PreQuestionCreater';
import RenderPredefineInputs from '../InputComponents/RenderPredefineInputs';

function PreviewModel(props) {
    const {show,onHide,questionList,setQuestionList,groupData, setGroupData ,question,groups, setGroups,groupDetail,setGroupDetail,unqGroup,setUnqGroup,setEditQuestion,editQuestion,editQuestionId,templeteName,templeteType} =props;
    const [inputRenderer, setInputRenderer] = useState();
    const [isChecked, setIsChecked] = useState(false);
    
    const handleOnChange = () => {
      setIsChecked(!isChecked);
     
    };
    const colourOptions =[ 
    { value: 'dob', label: 'Date of Birth', color: '#e4dbf4', isFixed: true },
    { value: 'height', label: 'Height', color: '#0052CC', isDisabled: false ,isFixed: true},
    { value: 'weight', label: 'Weight', color: '#0052CC', isFixed: true },
    { value: 'bmi', label: 'BMI', color: '#0052CC', isFixed: true },
    ];

    const generateInput =(e) =>{
      setInputRenderer(<RenderPredefineInputs inputData ={e}/>);
    }


  return (
    <> 
    <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add question to {templeteName} - { templeteType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            

  <div className='d-flex justify-content-center'>
        <div>
        Want to have pre-defined question
        <label class="switch">
                  <input type="checkbox" name='toggleBtn' checked={isChecked} onChange={handleOnChange} />
                  <span class="slider round"></span>
                </label>
                 </div>
                 </div>
            
          }
      
{
  isChecked ? (<>
   <div className="form-group  floating-label pt-4">
                <div className="mb-3">

              <CreatableSelect isMulti options={colourOptions}   onChange={(e) => generateInput(e)} />
              {inputRenderer}
           </div>
              </div>
  </>):(<><PreQuestionCreater questionList={questionList} groupDetail={groupDetail} setGroupDetail={setGroupDetail} groups={groups} setGroups={setGroups} question={question} setQuestionList={setQuestionList} groupData={groupData} setGroupData={setGroupData} onHide={onHide} unqGroup={unqGroup} setUnqGroup={setUnqGroup} editQuestion={editQuestion} editQuestionId={editQuestionId}/></>)
}
          


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PreviewModel