import React,{useState,useEffect} from 'react'
import { Outlet } from 'react-router-dom'
import HeaderWrapper from './header/HeaderWrapper'
import FooterWrapper from './footer/FooterWrapper'
import SideMenu from '../component/sidebar/SideMenu'

function MasterLayout() {

  const [sidebarOpen, setSideBarOpen] = useState(true);
  const [sideBar,setSideBar] = useState("col-3 ");
  const [contentCss,setContentCss] = useState("col-9");
  const [width, setWidth]   = useState(window.innerWidth);
const [height, setHeight] = useState(window.innerHeight);
const updateDimensions = () => {
  setWidth(window.innerWidth);
  setHeight(window.innerHeight);
}
useEffect(() => {
  window.addEventListener("resize", updateDimensions);
  return () => window.removeEventListener("resize", updateDimensions);

  

},[])
useEffect(() => {
  if(width >= 991){
    setContentCss("col-9");
  }else {
    setContentCss("col-12");
  }
  
  

},[width])

  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
    setContentCss(sidebarOpen ? "content open col-11" : "content col-9");
    if(sidebarOpen){
      setSideBar("col-1");
      //setContentCss("col-11 slow-transition")
    }else{
      setSideBar("col-3");
      //setContentCss("col-9 slow-transition")
    }
  };

  return (
    <div>
      <div className='fluid-container'>
       
       
        <div className='minHeight'>
          <div className='row'>
          
            <div className={sideBar }>
              {
                <SideMenu isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />

              }
            
      </div>
          
            <div className={contentCss }> 
            
            <HeaderWrapper />
            <Outlet /> </div>
           
            </div>
          </div>
          <FooterWrapper />
        </div>
      
    </div>


  )
}

export default MasterLayout