import React from 'react'
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import * as Icon from 'react-bootstrap-icons';
import Logout from '../../../module/auth/component/Logout';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
function UserProfilePopOver(props) {
    const {side} =props;
    const email = useSelector((state) => state.auth.email);
  const name = useSelector((state) => state.auth.name);
  const role = useSelector((state) => state.auth.role);
  return (
    <>
      {[ side].map((placement) => (
        <OverlayTrigger
          trigger="click"
          key={placement}
          placement={placement}
          overlay={
            <Popover id={`popover-positioned-${placement}`}>
              <Popover.Header as="h3">
              <div className="d-flex justify-content-start">
                <div className='p-0'>
                
		      		<span className="fa fa-user-o"><Icon.PersonCircle size={45}/></span>
                
                </div>
                <div className='ms-2'>
                    <div>{name} </div>
                    <div>{email}</div>

                </div>
              </div>
                </Popover.Header>
              <Popover.Body>
                <div className='p-2'>
             {role == 'doctor' &&  <div className='p-1'><Link to='/auth/doc/doctor-setting'>Setting</Link> </div>}
            
             <div className='p-1'> <Logout/> </div>
             </div>
              </Popover.Body>
            </Popover>
          }
          rootClose
        >
          
          <div className="d-flex justify-content-start">
            
          <div className="icon d-flex align-items-center justify-content-center">
		      		<span className="fa fa-user-o"><Icon.PersonCircle size={35}/></span>
              
		      	</div>
                </div>
          
        </OverlayTrigger>
      ))}
    </>
  )
}

export default UserProfilePopOver