import React, { useState ,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { RouteData } from '../../Utils/RouteConstant';
import AccordionMenu from '../accordion/AccordionMenu';
import './sidebar.scss';
import { ReactComponent as Logo } from './../../svg/logo.svg';
import { ReactComponent as Dashboard } from './../../svg/dashboard-layout-svgrepo-com.svg';
import { ReactComponent as Casesheet } from './../../svg/register-svgrepo-com.svg';
import { ReactComponent as Appointment } from './../../svg/appointment-new-svgrepo-com.svg';
import { ReactComponent as Logout } from './../../svg/logout-svgrepo-com.svg';

import Accordion from 'react-bootstrap/Accordion';
import MenuWrapper from './MenuWrapper';
import MenuItem from './MenuItem';
import * as Icon from 'react-bootstrap-icons';
import { saveAuth} from './../../reducer/authReducer';

import {  useDispatch } from 'react-redux';

function SideMenu(props) {
  const dispatch = useDispatch()
  const role = useSelector((state) => state.auth.role);
  const [isOpenState, setIsOpenState] = useState(props.isOpen);
 const [cssStyle,setCssStyle ]=useState("");
  const { isOpen, toggleSidebar } = props;
  const sidebarClass = props.isOpen ? "sidebar open" : "sidebar close";

  const contentDisplay = props.isOpen ? "display-show" : "display-block";
  const isTrue = props.isOpen ? true : false;
  const dashboardCss = props.isOpen ? "icon-display  " : "icon-display  pad-dash";
  const asideWidth = props.isOpen ? "wid-full" : "wid-half";
  const arrow = props.isOpen ? <Icon.CaretLeftFill size={30} /> : <Icon.CaretRightFill size={30} />;
  const [width, setWidth]   = useState(window.innerWidth);

  function sideMenuHover() {
  

    // if(!isTrue){
    toggleSidebar()
    //}
  }

  const updateDimensions = () => {
    setWidth(window.innerWidth);
   // setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  
    
  
  },[])

  useEffect(() => {
    if(width <= 991){
    setCssStyle("d-none");
    }else {
      setCssStyle(" d-block");
     
    }
  },[width])

  function logOut(){
    dispatch(saveAuth(undefined));
}


  return (
    <div className={"sidebar open "+cssStyle} >
      {
        <button className='collapse-button btn btn-primary rounded' onClick={toggleSidebar}>
          <span className="fa fa-user-o">{arrow}</span>
        </button>
      }
    
      <div className={'aside flex-column-fluid ' + asideWidth}>

        <div className={" menu-wrapper"}>
          <div className="">
            <div class="p-4 " >

              <div className='logo-header '><Logo /> <span className={"mx-2 " + contentDisplay}>AccuitySavvy</span>
              </div>

            </div>
           </div>
            <div>

              <Link to="dashboard" aria-expanded="false" class="accordion-button collapsed">

                <div className={dashboardCss}>
                  <Dashboard />
                </div><span className={' ' + contentDisplay}>Dashboard</span></Link>


              <Accordion >

                {
                  RouteData.map((key, value) => (
                    <>
                      {
                        (role == key.role) ?
                          <>

                            {
                              key.value.map((module, modI) => (
                                <>
                                  {


                                    isTrue ? (<><Accordion.Item eventKey={modI}>
                                       
                                      <Accordion.Header>{
                                        module.menuCategory == 'Casesheet Module' && <> <Casesheet /><span className={"mx-2 " + " display-show"}>{module.menuCategory}</span></>
                                      }
                                        {
                                          module.menuCategory == 'Appointments' && <> <Appointment /><span className={"mx-2 " + " display-show"}>{module.menuCategory}</span></>
                                        }
                                        {
                                          module.menuCategory == 'survey' && <> <Casesheet /><span className={"mx-2 " + " display-show"}>{module.menuCategory}</span></>
                                        }
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <MenuWrapper content={module.menus} />

                                      </Accordion.Body>
                                    </Accordion.Item>
                                    </>) : (<>

                                      {
                                        module.menuCategory == 'Casesheet Module' && <>
                                          <Link to="/auth/doc/create-pre-questions" aria-expanded="false" class="accordion-button collapsed" >

                                            <div className='icon-display'>
                                              <Casesheet />
                                            </div><span className={' ' + contentDisplay}>Casesheet</span></Link>

                                        </>
                                      }
                                      {
                                        module.menuCategory == 'Appointments' && <>
                                          <Link to="/auth/bk/booked-appointment" aria-expanded="false" class="accordion-button collapsed">

                                            <div className='icon-display'>
                                              <Appointment />
                                            </div><span className={' ' + contentDisplay}>Appointment</span></Link>
                                        </>
                                      }
                                      {
                                        module.menuCategory == 'survey' && <>
                                          <Link to="/auth/bk/booked-appointment" aria-expanded="false" class="accordion-button collapsed">

                                            <div className='icon-display'>
                                              <Casesheet />
                                            </div><span className={' ' + contentDisplay}></span></Link>
                                        </>
                                      }

                                    </>)




                                  }
                                </>

                              )
                              )
                            }

                          </>
                          : ""
                      }
                    </>
                  ))
                }

              </Accordion>
            </div>

            {


              <div>
                <div className="d-flex justify-content-start p-4">

                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa fa-user-o"><Logout /><span className={"mx-2 " + contentDisplay +' hover-cursor'} onClick={logOut}>Logout</span> </span>

                  </div>
                </div>
              </div>


            }

          </div>

        </div>
      </div>
    
  )
}

export default SideMenu