import React from 'react'
import { Link } from 'react-router-dom'

function ForgetPassword() {
  return (
    <>
      <h3 className="text-center mb-4">Forget Your password</h3>
      <form action="#" className="login-form">
        <div className="form-group">
          <div className="mb-3">

            <input type="text" className="form-control" placeholder="Your verification Code" />
          </div>
        </div>
        <div className="form-group">
          <div className="mb-3">

            <input type="password" className="form-control" placeholder="Password" />
          </div>
        </div>
        <div className="form-group">
          <div className="mb-3">

            <input type="password" className="form-control" placeholder="New Password" />
          </div>
        </div>
        <div className="form-group d-md-flex">
          <div className="w-50">
            <label className="checkbox-wrap checkbox-primary">Remember Me
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="w-50 text-md-right">

            <Link to='/auth/login' className='link'>Sign In </Link>
          </div>
        </div>
        <div className="form-group">
          <button type="submit" className="btn btn-primary rounded submit p-2 px-4">Get Started</button>
        </div>
      </form>
    </>
  )
}

export default ForgetPassword