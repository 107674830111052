import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Dropdowncommon from '../../component/dropdowncommon/Dropdowncommon'
import CreateTemplete from '../../component/modelPopup/CreateTemplete'
import * as service from './../../service/SurveyService';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constant from '../../Utils/MapConstant';
import * as popUp from '../../component/MessagePopup/MessagePopupComp';

function QuestionsTemplateList() {
  const [show, setShow] = useState(false);
  const [templates, setTemplates] = useState();
  const [template, setTemplate] = useState();
  const userId = useSelector((state) => state.auth.userId);
  const [isLoading, setIsLoading] = useState(true);
  const handleClose = () => {

    setShow(false);
  }
  const handleShow = (value) => {
    if (value === "") {
      setTemplate(undefined);
    } else {
      setTemplate(value);
    }

    setShow(true);
  }

  async function deleteTemplete(templeteId) {
    setIsLoading(true);
    const template = await service.deleteTemplete(userId, templeteId);

    if (!(template.ErrorCode != undefined)) {
      popUp.MessagePopupComp('Templete Id ' + templeteId + ' Deleted successfully ',constant.Success);
     } else {
      popUp.MessagePopupComp(template.message,constant.Error);
      }

    const templateData = await service.getTempletes(userId);
    setTemplates(templateData.data);
    setIsLoading(false);
  }

  useEffect(() => {
    const fetchMyAPI = async () => {
      const template = await service.getTempletes(userId);
      setTemplates(template.data);
      setIsLoading(false);
    }
    fetchMyAPI();
  }, [show])

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='card mt-4'>
            <div >
              <div className='d-flex justify-content-between'>
                <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1 p-4'> Form workspace</div>

                <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1 p-4'>
                  <button type='submit' className="btn btn-primary rounded submit px-2" onClick={() => handleShow("")}>Create form</button>

                  <CreateTemplete show={show} onHide={handleClose} templeteProp={template} />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card p-4'>

            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Form Name</th>
                  <th scope="col">Form ID</th>
                  <th scope="col">Questions</th>
                  <th scope="col">response</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">updated Date</th>
                  <th scope="col">Form Type</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ? ("Loading") :
                    templates.map((data) =>
                      data.templeteType === 'TM' &&
                      <tr>
                        <th scope="row"><Link to={"/auth/doc/manage-templete/" + data.templeteId}>{data.templeteName}</Link></th>
                        <td>{data.templeteId}</td>
                        <td>{data.questionNo}</td>
                        <td>0</td>
                        <td>{data.createdDate}</td>
                        <td>{data.modifiedDate}</td>
                        <td>{data.groupType}</td>
                        <td><Dropdowncommon show={show} handleShow={handleShow} handleClose={handleClose} templeteId={data.templeteId} templete={data} deleteTemplete={deleteTemplete} /></td>
                      </tr>
                    )
                }
              </tbody>
            </table>

          </div>
        </div>

      </div>

      <div className='row'>
        <div className='col-12'>
          <div className='card p-4'>

            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Form Name</th>
                  <th scope="col">Form ID</th>
                  <th scope="col">Questions</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">updated Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ? ("Loading") :
                    templates.map((data) =>
                      data.templeteType === 'TG' &&
                      <tr>
                        <th scope="row">{data.templeteName}</th>
                        <td>{data.templeteId}</td>
                        <td>{data.questionNo}</td>
                        <td>{data.createdDate}</td>
                        <td>{data.modifiedDate}</td>
                        <td><Dropdowncommon show={show} handleShow={handleShow} handleClose={handleClose} templeteId={data.templeteId} templete={data} deleteTemplete={deleteTemplete} /></td>
                      </tr>
                    )
                }
              </tbody>
            </table>

          </div>
        </div>

      </div>
    </>

  )
}

export default QuestionsTemplateList