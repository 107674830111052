import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

const GET_CASE_SHEET_FOR_DOCTOR = `${API_URL}/paitent-casesheet`;
const GET_ALL_QUESTION= `${API_URL}/all-question`;
const PAITENT_CASESHEET= `${API_URL}/paitent-casesheet`;

export function getCaseSheetForDoctor(doctorId) {
    const config = {
      headers: {
        'userId': doctorId,
       }
    };
    return axios.get(GET_CASE_SHEET_FOR_DOCTOR,
      config
    )
  }

  export function getAllQuestion(doctorId) {
    const config = {
      headers: {
        'userId': doctorId,
       }
    };
    return axios.get(GET_ALL_QUESTION,
      config
    )
  }

  export function paitentCasesheet(doctorId,paitentId) {
    let finalURL = PAITENT_CASESHEET +"/"+paitentId;
    const config = {
      headers: {
        'userId': doctorId,
       }
    };
    return axios.get(finalURL,
      config
    )
  }