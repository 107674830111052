import React, { useEffect } from 'react'
import * as service from './../../service/DoctorModuleService';
import * as Apiservice from './../../service/ApiService';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import QuestionCard from '../../component/questionDisplaycard/QuestionCard';
import * as qservice from './../../service/SurveyService';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { PrintQuestionComponent } from '../../component/printerComponent/PrintQuestionComponent';
import * as constant from '../../Utils/MapConstant';
import * as popUp from '../../component/MessagePopup/MessagePopupComp';

function PaitentCasesheetLayout() {
  const userIdSession = useSelector((state) => state.auth.userId);
  const [paitentList, setPaitentList] = useState();
  const [paitent, setPaitent] = useState();
  const [caseSheetData, setCaseSheetData] = useState();
  const [loading, setloading] = useState(true);
  const [paitentObj, setPaitentObj] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [nodataError, setNodataError] = useState(false);
  const [ bgPrimLite, setBgPrimLite] = useState("bgPrimWhite");

const [date, setDate] =useState();
  const fetchMyAPI = async () => {
    const data = await Apiservice.getAppointments(userIdSession, userIdSession, date);
    if(data.data.length == 0 ){
      setNodataError("No Appointment for the today");
    }else {
      setPaitentList(data.data)
      setPaitent(data.data[0].paitentDetails.p_id);
      setPaitentObj(data.data[0]);
      setNodataError(false)
    }
    setloading(false);
  }
  useEffect( () => {
    const getUser = async () => {
      if(date ===undefined){
        setDate(moment().format('DD-MM-yyyy'))    
      }
      fetchMyAPI()
      
    };
    getUser();
    }, [date]);
    
  const fetchPaitentCasesheetAPI = async () => {
    const data = await service.paitentCasesheet(userIdSession, paitent)
    setCaseSheetData(data.data);
    setloading(false);
  }

  function handleChange(id, value, inputValue, type) {
   
    caseSheetData.mainTemplateMetaData.mainTemplate.map((questionData) => {
      if (questionData.questionId === inputValue) {
   
        if (type === 'checkbox') {
          if (questionData.answer != undefined) {
            let intArr;
            if (typeof (questionData.answer) == "string") {
              intArr = [questionData.answer];
            } else {
              intArr = questionData.answer;
            }
            let index = intArr.indexOf(value);
            if (index > -1) {
              intArr.splice(index, 1);
              questionData.answer = intArr;
            } else {
              intArr.push(value);
              questionData.answer = intArr;
            }

          } else {
            let intArr = [];
            intArr = intArr.push(value);
            questionData.answer = [value];
          }
        } else {
          questionData.answer = value;
        }
      }
    }
    )
    if (type !== 'text') {
      setloading(true);

    }
    setCaseSheetData(caseSheetData);
  }
  const printData  =useReactToPrint({
    content: () => componentRef.current,
  });

  const saveData = async () => {
    setButtonLoading(true)
    let data = [];
    caseSheetData.mainTemplateMetaData.mainTemplate.map((questionData) => {
     if(questionData.answer != null){
      data.push({ "id": questionData.questionId, "value": questionData.answer.toString() });
     }else{
      data.push({ "id": questionData.questionId, "value": questionData.answer });
     }
      
    })
    let answerData = { pId: paitent, surveyAnswer: data, formType: "casesheet" };
    const template = await qservice.postSurveyAnswer(userIdSession, answerData);
    popUp.MessagePopupComp('Casesheet Updated successfully for Paitent Id - ' + paitent,constant.Success);
    setButtonLoading(false);
  }

  const setSelectPaitent = (paitentId) => {
    paitentList.map((pdata)=>{
   if(pdata.paitentDetails.p_id == paitentId){
  setPaitent(paitentId);
  document.getElementById(paitent).style.background="#FFF"
  setPaitentObj(pdata);
}
document.getElementById(paitentId).style.background="#7042c130";
    })
  }

  useEffect(() => {
    setloading(false);
  }, [loading])


  useEffect(() => {
    fetchMyAPI();
  }, [])
  useEffect(() => {
    setCaseSheetData(undefined);
    fetchPaitentCasesheetAPI();
  }, [paitent])
  const componentRef = useRef();

  console.log("Paitent caseSheetData ::"+JSON.stringify(caseSheetData))

  return (
    <div className='row p-2 mt-4'>
      <div className='display-block'>
        {
          caseSheetData != undefined && <PrintQuestionComponent ref={componentRef} questions={caseSheetData.mainTemplateMetaData.mainTemplate}/>
        }
     
      </div>
     
      <div className='card p-2 mt-2'><div className="row justify-content-center">  
      <div className='row'>
        <div className='col-3'></div>
        <div className='col-3'></div>
        <div className='col-3'></div>
        <div className='col-3'>
        <DateRangePicker
       initialSettings={{
         singleDatePicker: true,
         showDropdowns: true,
         startDate: new Date(),
         minYear: 1901,
         maxYear: parseInt(moment().format('YYYY'), 10),
        }}
        onCallback={(start) => {
         setDate(start.format('DD-MM-yyyy'))
        }}
     >
       <input type="text" className="form-control col-4" />
     </DateRangePicker>

        </div>
      </div>
      </div></div>
      {
        nodataError  ? (<div className='card p-4 mt-2'><div className="row justify-content-center"> {nodataError} </div></div>) :(
          <>
          <div className='col-9 mt-2'>
          <div className='card'>
            <div className={'card-header '}>
  
              <div className='d-flex justify-content-between'>
                <div className='fs-5 fw-bolder text-gray-900 text-hover-primary  lh-1 pt-4'>Paitent Details</div>
                <div className='fs-5 fw-bolder text-gray-900 text-hover-primarylh-1'>

  <button type='submit' className="btn btn-primary rounded submit " onClick={saveData} disabled={loading}>
                      {!buttonLoading && <span className='indicator-label'>Save</span>}
                      {buttonLoading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
  
                    </button>
                    <button type='submit' className="btn btn-primary rounded submit " onClick={printData} disabled={loading}>
                      {!buttonLoading && <span className='indicator-label'>Print</span>}
                      {buttonLoading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
  
                    </button>

                </div>
             
              </div>
              </div>
              <div className='row p-4'>
                <div className='col-6'>
                  <div className='row'>
                    <div className='col-12'>
                     <span className='fs-6 fw-bolder text-gray-900'>Name :</span> 
                    <span className='mx-2'>
                      {
                      paitentObj != undefined &&
                      paitentObj.paitentDetails.firstName+ " "+ paitentObj.paitentDetails.middleName +" "+ paitentObj.paitentDetails.lastName}
                    </span>
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='row'>
                    <div className='col-6'>
                    <span className='fs-6 fw-bolder text-gray-900'> Paitent ID : </span>
                      <span className='mx-2'>
                    {
                      paitentObj != undefined &&
                      paitentObj.paitentDetails.p_id}
                 </span>
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                <div className='row'>
                     <div className='col-12'>
                  <span className='fs-6 fw-bolder text-gray-900'>   Age : </span>
                      <span className='mx-2'>
                    {
                      paitentObj != undefined &&
                      paitentObj.paitentDetails.age}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='row'>
                  <div className='col-12'>
                  <span className='fs-6 fw-bolder text-gray-900'>  Gender : </span>
                      <span className='mx-2'>
                    {
                      paitentObj != undefined &&
                      paitentObj.paitentDetails.gender}
                      </span>
                    </div>
                  </div>
                </div>
             
              
                <div className='col-6'>
                  <div className='row'>
                    <div className='col-12'>
                    <span className='fs-6 fw-bolder text-gray-900'> Mobile : </span>
                      <span className='mx-2'>
                    {
                      paitentObj != undefined &&
                      paitentObj.paitentDetails.mobileNumber}
                      </span>
                    </div>
                  </div>
                </div>
               </div>
          </div>
  
          {!loading && caseSheetData && <>
            <div className='card mt-2'>
              <div className={'card-header '}>
                <div className='d-flex justify-content-between'>
                  <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>{caseSheetData.feTemplateMetadata.templeteData.templeteName}</div>
                </div>
                <div>
                </div>
              </div>
              <div className='p-2'>
                {
                  caseSheetData.feTemplateMetadata.mainTemplate.map((question) =>
                    <>
                      <QuestionCard question={question} index={question.questionId} handleChange={handleChange} />
                    </>
  
                  )
                }
              </div>
            </div>
            <div className='card mt-2'>
              <div className={'card-header '}>
                <div className='d-flex justify-content-between'>
                  <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>{caseSheetData.mainTemplateMetaData.templeteData.templeteName}</div>
                </div>
                <div>
                </div>
              </div>
              <div className='p-2'>
  
                {
                  caseSheetData.mainTemplateMetaData.mainTemplate.map((question) =>
                    <>
                      <QuestionCard question={question} index={question.questionId} handleChange={handleChange} />
                      {
  
                        question.group.map((groupOption) =>
                          <>
                            <>
                            </>
  
                            {
                              question.answer != undefined && (question.answer === groupOption.optionLabel || question.answer.indexOf(groupOption.optionLabel) > -1) &&
                              groupOption.group.map((metaSubGroup) =>
                                <>
  
                                  {
                                    caseSheetData.questionsGroup.map((subGroupData) =>
                                      <>
  
                                        {
                                          subGroupData.groupName == metaSubGroup.label &&
                                          subGroupData.questions.map((subQuestions) =>
                                            <div className='pad-side-100'>
                                              {
                                                <QuestionCard question={subQuestions} index={subQuestions.questionId} />
                                                //  "groyyyy"
                                              }
                                            </div>
                                          )
                                        }
                                      </>
                                    )
                                  }
                                </>
                              )}
                          </>
                        )
                      }
                      {
  
                      }
                    </>
                  )
                }
                <div className='d-flex justify-content-between'>
                  <div>
  
                  </div>
                  <div>
                    <button type='submit' className="btn btn-primary rounded submit p-2 px-4" onClick={saveData} disabled={loading}>
                      {!buttonLoading && <span className='indicator-label'>Save</span>}
                      {buttonLoading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
  
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>}
        </div>
  
        <div className='col-3 mt-2'>
          <div className='card '>
            {
              paitentList &&
              paitentList.map((paitent) =>
               <div id={paitent.paitentDetails.p_id} className={'card  p-2 m-2 hover-cursor '+bgPrimLite} onClick={() => setSelectPaitent(paitent.paitentDetails.p_id)}>
               {paitent.paitentDetails.p_id}- {paitent.paitentDetails.firstName  + " " + paitent.paitentDetails.lastName }
             </div>
               )
            }
          </div>
  
        </div>
        </>
        )
          }
     

    </div>
  )
}



  const PaitentListCard =  (props) => {
    const {bgPrimLite,setBgPrimLite,pId,fname,lname,setSelectPaitent} =props;
    const [bgPrimLiteLoc, setBgPrimLiteLoc] = useState("bgPrimWhite")
    function changeColor(pId){
      setBgPrimLite("bgPrimWhite");
     // setBgPrimLite("bg-pri-lite");
      
      
      setSelectPaitent(pId)
    }
  return (
    <div className={'card  p-2 m-2 hover-cursor '+bgPrimLite} onClick={() => changeColor(pId)}>
    {pId}- {fname + " " + lname}
  </div>
  )
}

export default PaitentCasesheetLayout