import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuthLayout from './AuthLayout'
import ForgetPassword from './component/ForgetPassword'
import Login from './component/Login'
import Register from './component/Register'

function AuthRouter() {
    return (
        <Routes>
            <Route element={<AuthLayout />} >
                <Route path='login' element={<Login />} />
                <Route path='register' element={<Register />} />
                <Route path='forget-password' element={<ForgetPassword />} />
                <Route path='*' element={<Login />} />
                <Route index element={<Login />} />
            </Route>
        </Routes>
    )
}

export default AuthRouter