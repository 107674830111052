import axios from 'axios';


const API_URL = process.env.REACT_APP_API_URL
export const LOGIN_URL = `${API_URL}/login`
// Server should return AuthModel
export function login(email, password) {
    return axios.post(LOGIN_URL, {
      email,
      password,
    },{
      headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*'
      }
  }
)
  }

 