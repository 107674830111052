import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ErrorLayout from './ErrorLayout';
import Error404 from './components/Error404';
import Error500 from './components/Error500';
function ErrorPage() {
  return (
    <Routes>
        <Route element={<ErrorLayout/>}>
        <Route path='404' element={<Error404/>} />
        <Route path='500' element={<Error500/>} /> 
        <Route index element={<Error404 />} />
        </Route>

    </Routes>
  )
}

export default ErrorPage