import React from 'react'
import * as Icon from 'react-bootstrap-icons';
import { Outlet } from 'react-router-dom';

function AuthLayout() {
  return (
    <div className="ftco-section">
      <div className="container">
			<div className="row justify-content-center">
				<div className="col-md-6 text-center mb-5">
					<h2 className="heading-section">D-App</h2>
				</div>
			</div>
			<div className="row justify-content-center">
				<div className="col-md-6 col-lg-5">
					<div className="login-wrap p-4 p-md-5">
		      	<div className="icon d-flex align-items-center justify-content-center">
		      		<span className="fa fa-user-o"><Icon.PersonCircle size={70}/></span>
              
		      	</div>
		      	
            <Outlet/>
	        </div>
				</div>
			</div>
		</div>
    </div>
  )
}

export default AuthLayout