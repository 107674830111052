import React,{useState,useEffect} from 'react'
import { Outlet } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { useSelector } from 'react-redux';
import * as service from './../../service/ApiService';
import * as surveyService from './../../service/SurveyService';

function AppointmentLayout() {
  const [doctor, setDoctor] =useState();
  const [date, setDate] =useState();
  const [appointmentStats, setAppointmentStats] =useState();
  const [loading, setLoadind] =useState(false);
  const [bookedCount, setBookedCount] =useState(0);
  const [queued, setQueued] =useState(0);
  const [doneCount, setDoneCount] =useState(0);
  const [doctorList, setDoctorList] =useState([]);
  const [refresh, setRefresh] =useState(false);
  const [loadStat, setLoadStat] =useState(false);
  const [errMsg, setErrMsg] =useState();
  const userId = useSelector((state) => state.auth.userId);
  const role = useSelector((state) => state.auth.role);
  const userObj = useSelector((state) => state.auth.obj);

  
   //const data = await service.login(values.email,values.password);
   const getAppointmentStats = async (docId) => {
    if(date != undefined && docId != undefined){
      const appointmentStats = await surveyService.getAppointmentStatsFOrDoctor(userId,date,docId);
   
    setAppointmentStats(appointmentStats.data);

    appointmentStats.data.map((data)=>{
      setLoadStat(true);
      if(data.status=='booked'){
        setBookedCount(data.count);
      }else if(data.status=='done'){
        setDoneCount (data.count);
      }else if(data.status=='queued'){
        setQueued(data.count);
      }
      
    }
   )
    }else {
        setBookedCount(0);
        setDoneCount (0);
        setQueued(0);
     }
    
     setLoadStat(false);
  }
useEffect( () => {
  if(role == 'doctor'){
    const getDoctor = async () => {
      let data;
      if(date == undefined){
        setDate(moment().format('DD-MM-yyyy'))
         data = await service.getDoctor(userId,userId,moment().format('DD-MM-yyyy'));
      }else {
         data = await service.getDoctor(userId,userId,date);
      }
      setLoadind(true);
     
    if ((data.ErrorCode != undefined)) {
      setErrMsg(data.message)
}
    setDoctor(data.data);
    setLoadind(false)
    
    }
   
    getDoctor();
    
  }else{
    const getUsers = async () => {
      
      const data = await service.getDoctors(userId);
      setDoctorList(data.data);
    };
    getUsers();
  }
  getAppointmentStats();
}, [date]);


useEffect( () => {
  if(role == 'doctor'){
    const getDoctor = async () => {
      setLoadind(true);
      if(date == undefined){
        setDate(moment().format('DD-MM-yyyy'))
      }
      const data = await service.getDoctor(userId,userId,date);
      if ((data.ErrorCode != undefined)) {
            setErrMsg(data.message)
      }
      setDoctor(data.data);
      setLoadind(false);
      }
      getDoctor();
      }else{
  const getUser = async () => {
    if(date ===undefined){
      setDate(moment().format('DD-MM-yyyy'))
    }
    const data = await service.getDoctor(userId,doctor.doctorDetails.p_id,date);
    setDoctor(data.data);
  };
  getUser();
}
  }, [date]);
  

  useEffect( () => {
    if(role == 'doctor'){
      const data= getAppointmentStats(userId,date);
    }else if(doctor != undefined){
     const data= getAppointmentStats(doctor.doctorDetails.p_id,date);
    
    }
    
  },
  [date,refresh]);

const doctorValue = async(e) => {
  if(e.target.value != 'NA'){
    let docterObject = doctorList.find(
      el => el.p_id == e.target.value
    )
    
    console.log("e.target.value ::"+e.target.value)
    if(docterObject.appointmentConfigured == false){
      setErrMsg("Doctor Config not completed")
    }
  
    if(e.target.value.length !== 0){
      if(date ===undefined){
        setDate(moment().format('DD-MM-yyyy'))
      }
      const data = await service.getDoctor(userId,e.target.value,date);
      setDoctor(data.data);
    }else {
      setDoctor();
    }
    
    getAppointmentStats(e.target.value,date);
  }else {
    setDoctor(undefined);
  }
  
}
console.log("Doctor obj ::"+JSON.stringify(doctor))
return (
    <>
      <div className='card mt-4 p-3 border-radius'>
      
        <div className='d-flex justify-content-between'>
        <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>Appointment Module</div>
        
        {
          role != 'doctor' &&  <div className="form-group">
          <div className="mb-3">

            <select class="p-2 fs-8 form-control form-select form-select-solid form-select-md " name="language" onChange={doctorValue}>
            <option value="NA">Select a Doctor</option>
            {doctorList && doctorList.length > 0 && doctorList.map((doctor, index) => (
      <option key={index} value ={doctor.p_id}>Dr. {doctor.firstName + " "+doctor.lastName}</option>
    ))}
      </select>
     </div>
        </div>
        }
       
        <div>
          
       
          
        <DateRangePicker
       initialSettings={{
         singleDatePicker: true,
         showDropdowns: true,
         startDate: new Date(),
         minYear: 1901,
         maxYear: parseInt(moment().format('YYYY'), 10),
        }}
        onCallback={(start) => {
         setDate(start.format('DD-MM-yyyy'))
        }}
     >
       <input type="text" className="form-control col-4" />
     </DateRangePicker>

        
        </div>
      
        </div>
        { doctor && doctor !== undefined ? (
        <div className='col-12 p-2'>
          <div className='row'>

            <div className='col-4'>
            <div className="d-flex justify-content-start">
                <div className='col-5'>
                  <span className='fs-6 fw-bolder text-gray-900'>Id :</span>
                </div>
                <div className='col-7'>
                  <div> {doctor.doctorDetails.p_id} </div>
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div className='col-5'>
                  <span className='fs-6 fw-bolder text-gray-900'>Name :</span>
                </div>
                <div className='col-7'>
                  <div> Dr. {doctor.doctorDetails.firstName + " "+doctor.doctorDetails.lastName } </div>
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div className='col-5'>
                  <span className='fs-6 fw-bolder text-gray-900'>Specilization  :</span>
                </div>
                <div className='col-7'>
                  <div> {doctor.doctorDetails.specialization}</div>
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div className='col-5'>
                  <span className='fs-6 fw-bolder text-gray-900'>Mobile  :</span>
                </div>
                <div className='col-7'>
                  <div> {doctor.doctorDetails.mobileNumber}</div>
                </div>
              </div>
            </div>
            <div className='col-8'>

{
  !loadStat && (
    <div class="card-body">
    <div class="row g-2">
      <div class="col-3">
        <div class="sidebar-border-dashed d-flex flex-column justify-content-center rounded p-3 backgroud-blue">
          <div class="fs-2 fs-xxl-2x fw-bolder mb-1" data-kt-countup="true"
            data-kt-countup-value="28" data-kt-countup-prefix="">{bookedCount+queued + doneCount}</div>
          <div class="sidebar-text-muted fs-6 fw-bold">Total</div>
        </div>
      </div>
      <div class="col-3">
        <div class="sidebar-border-dashed d-flex flex-column justify-content-center rounded p-3 backgroud-green">
          <div class="fs-2 fs-xxl-2x fw-bolder mb-1" data-kt-countup="true"
            data-kt-countup-value="204" data-kt-countup-prefix="">{bookedCount}</div>
          <div class="sidebar-text-muted fs-6 fw-bold">Booked</div>
        </div>
      </div>
      <div class="col-3">
        <div class="sidebar-border-dashed d-flex flex-column justify-content-center rounded p-3 backgroud-red">
          <div class="fs-2 fs-xxl-2x fw-bolder mb-1" data-kt-countup="true"
            data-kt-countup-value="76" data-kt-countup-prefix="">{queued}</div>
          <div class="sidebar-text-muted fs-6 fw-bold">In Queued</div>
        </div>
      </div>
      <div class="col-3">
        <div class="sidebar-border-dashed d-flex flex-column justify-content-center rounded p-3 backgroud-yellow">
          <div class="fs-2 fs-xxl-2x fw-bolder mb-1" data-kt-countup="true"
            data-kt-countup-value="9" data-kt-countup-prefix="">{doneCount}</div>
          <div class="sidebar-text-muted fs-6 fw-bold">Completed </div>
        </div>
      </div>
    </div>
  </div>
  )
}
              
            </div>
          </div>

        </div>
        
     
        ):(errMsg)
}
</div>
{
doctor ? (
<div className='p-3'>
<Outlet context={[doctor,setDoctor,date,setRefresh]}/>
</div>
):( "")
}
    </>
  )
}

export default AppointmentLayout;