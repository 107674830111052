
import Logout from '../../module/auth/component/Logout';
import React,{useState,useEffect} from 'react'
import  "./header.scss";
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button'
import * as Icon from 'react-bootstrap-icons';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import UserProfilePopOver from './userProfilePopOver/UserProfilePopOver';
import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from './../../svg/logo.svg';
import { ReactComponent as Dashboard } from './../../svg/dashboard-layout-svgrepo-com.svg';
import { ReactComponent as Casesheet } from './../../svg/register-svgrepo-com.svg';
import { ReactComponent as Appointment } from './../../svg/appointment-new-svgrepo-com.svg';
import Accordion from 'react-bootstrap/Accordion';
import { RouteData } from '../../Utils/RouteConstant';
import MenuWrapper from '../../component/sidebar/MenuWrapper';

export default function HeaderWrapper() {
  const role = useSelector((state) => state.auth.role);
  const name = useSelector((state) => state.auth.name);
  const [width, setWidth]   = useState(window.innerWidth);
  //const role = useSelector((state) => state.auth.role);
  const [header, setHeader] = useState("header");
  const contentDisplay = "display-show";
  const dashboardCss ="icon-display  " ;
  const isTrue =  true ;
  const updateDimensions = () => {
    setWidth(window.innerWidth);
   // setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  },[])

  const listenScrollEvent = event => {
    if (window.scrollY < 73) { 
      return setHeader("header");
    } else if (window.scrollY > 70) {
      return setHeader("header2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);


  return (
    <div className={header}>
     <>
      {['xl'].map((expand) => (
        <Navbar key={expand}  expand={expand} className="mb-3">
          <Container fluid>
         {
          
<Navbar.Brand href="#">
 {
  width >= 991 ?( <h4>Hello, { role== 'doctor' ? ("Dr." + name) : (name)}</h4>) : (  
  <div className='logo-header '><Logo /> <span className={"mx-2 display-show white-color" }>AccuitySavvy</span>
  </div>) 
 }
  </Navbar.Brand>

          
         }   
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <h4>Hello, { role== 'doctor' ? ("Dr." + name) : (name)}</h4>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                 {
                  /*
                  <Nav.Link href="#action1">Home</Nav.Link>
                  <Nav.Link href="#action2">Link</Nav.Link>
                   <NavDropdown
                    title="Dropdown"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown>
                */
                  width >= 1200 ? (<UserProfilePopOver side="bottom"/>) :(<div><div className="">
                  
                 </div>
                  <div>
      
                    <Link to="dashboard" aria-expanded="false" class="accordion-button collapsed">
      
                      <div className={dashboardCss}>
                        <Dashboard />
                      </div><span className={' ' + contentDisplay}>Dashboard</span></Link>
      
      
                    <Accordion >
      
                      {
                        RouteData.map((key, value) => (
                          <>
                            {
                              (role == key.role) ?
                                <>
      
                                  {
                                    key.value.map((module, modI) => (
                                      <>
                                        {
      
      
                                          isTrue ? (<><Accordion.Item eventKey={modI}>
                                             
                                            <Accordion.Header>{
                                              module.menuCategory == 'Casesheet Module' && <> <Casesheet /><span className={"mx-2 " + " display-show"}>{module.menuCategory}</span></>
                                            }
                                              {
                                                module.menuCategory == 'Appointments' && <> <Appointment /><span className={"mx-2 " + " display-show"}>{module.menuCategory}</span></>
                                              }
                                              {
                                                module.menuCategory == 'survey' && <> <Casesheet /><span className={"mx-2 " + " display-show"}>{module.menuCategory}</span></>
                                              }
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <MenuWrapper content={module.menus} />
      
                                            </Accordion.Body>
                                          </Accordion.Item>
                                          </>) : (<>
      
                                            {
                                              module.menuCategory == 'Casesheet Module' && <>
                                                <Link to="/auth/doc/create-pre-questions" aria-expanded="false" class="accordion-button collapsed" >
      
                                                  <div className='icon-display'>
                                                    <Casesheet />
                                                  </div><span className={' ' + contentDisplay}>Casesheet</span></Link>
      
                                              </>
                                            }
                                            {
                                              module.menuCategory == 'Appointments' && <>
                                                <Link to="/auth/bk/booked-appointment" aria-expanded="false" class="accordion-button collapsed">
      
                                                  <div className='icon-display'>
                                                    <Appointment />
                                                  </div><span className={' ' + contentDisplay}>Appointment</span></Link>
                                              </>
                                            }
                                            {
                                              module.menuCategory == 'survey' && <>
                                                <Link to="/auth/bk/booked-appointment" aria-expanded="false" class="accordion-button collapsed">
      
                                                  <div className='icon-display'>
                                                    <Casesheet />
                                                  </div><span className={' ' + contentDisplay}></span></Link>
                                              </>
                                            }
      
                                          </>)
      
      
      
      
                                        }
                                      </>
      
                                    )
                                    )
                                  }
      
                                </>
                                : ""
                            }
                          </>
                        ))
                      }
      
                    </Accordion>
                  </div>
      
                  {
      
      
                    <div>
                      <div className="d-flex justify-content-start p-4">
      
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-user-o"><Logout /><span className={"mx-2 " + contentDisplay}></span> </span>
      
                        </div>
                      </div>
                    </div>
      
      
                  }
      
                </div>) 
                 } 
                 
                 
                </Nav>
                
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
      </div>
  )
}
