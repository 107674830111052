import React,{useState,useEffect} from 'react'

function InputOptionLoader(props) {
    const {question, inputId,handleChange} =props;
    const [selected, setSelected] = useState();
    const [optionHtml, setOptionHtml] = useState();
    const [questionState, setQuestionState] = useState(question);
    
   function updateSelectedData(id,value,inputValue){
   
    handleChange(id, value, inputValue);
   
   }
    useEffect(() => {
        if (question.questionType == 'text') {
            setOptionHtml(<input className="form-control" defaultValue={questionState.answer} onChange={e => handleChange('id', e.target.value, inputId,'text')}></input>);
        } else if (question.questionType == 'textArea') {
            setOptionHtml(<textarea id="inputValueArea" value={question.answer} className="form-control h100" rows="4" cols="50" type="textarea" placeholder=" " name="optionExample" onChange={e => handleChange('id', e.target.value, inputId,'textArea')}></textarea>)
    
        } else if (question.questionType == 'radio') {
            let htmlRadio = []
            question.optionsData.map((data, index) =>
            {
                setSelected(data)
               // setSelected(data);
                question.answer == data ? (  
                     htmlRadio.push(<div className="form-check form-check-inline">
            <input className={"form-check-input font-14"}
                name={question.questionId }
                type="radio"
                defaultValue={data}
                id={question.questionId }
                onChange={(e) => updateSelectedData('id', e.target.value, inputId,'radio')}
                checked={(question.answer === data) }
            />
            <label className="form-check-label font-14" htmlFor="inlineRadio1"> {data }</label>
        </div>)
        ) :(
                htmlRadio.push(<div className="form-check form-check-inline">
                    <input className={"form-check-input font-14"}
                        name={question.questionId }
                        type="radio"
                        defaultValue={data}
                        id={question.questionId }
                        onChange={(e) => handleChange('id', e.target.value, inputId,'radio')}
                        //checked={(selected === data) }
                    />
                    <label className="form-check-label font-14" htmlFor="inlineRadio1"> {data }</label>
                </div>))
                
            }
    
            )
    
            setOptionHtml(htmlRadio);
        }
        else if (question.questionType == 'checkbox') {
    
            let htmlRadio = []
            question.optionsData.map((data, index) =>
            question.answer!= undefined  && question.answer.indexOf(data)  > -1 ? ( htmlRadio.push(<div className="form-check form-check-inline">
            <input className={"form-check-input font-14"}
                 name={question.questionId }
                type="checkbox"
                defaultValue={data}
                id={question.questionId }
                onChange={e => handleChange('id', e.target.value, inputId,'checkbox')}
                checked={((question.answer.indexOf(data)  > -1 ) ) }
               // checked={true}
            />
            <label className="form-check-label font-14" htmlFor="inlineRadio1"> {data}</label>
        </div>)) : ( htmlRadio.push(<div className="form-check form-check-inline">
                    <input className={"form-check-input font-14"}
                         name={question.questionId }
                        type="checkbox"
                        defaultValue={data}
                        id={question.questionId }
                        onChange={e => handleChange('id', e.target.value, inputId,'checkbox')}
                     //   checked={false}
                    />
                    <label className="form-check-label font-14" htmlFor="inlineRadio1"> {data}</label>
                </div>))
               
    
            )
    
            setOptionHtml(htmlRadio);
        }
        else if (question.questionType == 'dropdown') {
    
            let htmlRadio = []
            htmlRadio.push(<select class="form-select" aria-label="Default select example"> onChange={e => handleChange('id', e.target.value, inputId,'dropdown')}
                <option selected>Open this select menu</option>
                {question.optionsData.map((data, index) =>
                    <option value="1">{data}</option>
                )
                }
            </select>
            )
            setOptionHtml(htmlRadio);
        }
    }, [selected,question])
    
    
  return (
    <div>
        {optionHtml}
        
    </div>
  )
}

export default InputOptionLoader