import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import CreateTemplete from '../../component/modelPopup/CreateTemplete';
import * as service from './../../service/SurveyService';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Dropdowncommon(props) {

  const { templeteId,deleteTemplete,templete,show,handleShow,handleClose } =props;
  const userId = useSelector((state) => state.auth.userId);
  function edit(){
    handleShow(templete)
  }
  function deleteTemp(){
    deleteTemplete(templeteId);
  }
  const [copyLabel,setCopyLabel] =useState('Copy Link');
  function copyLink (){
    navigator.clipboard.writeText('Copy this text to clipboard nnnnnnnnnn');
    setCopyLabel("Copied!");

    setInterval(() => {
      setCopyLabel("Copy Link");
    },1500)
  }
 const markAsDefault= (value)=> async (e)=>{
  
  const template = await service.setDefaultCaseSheet(userId,templeteId,value);
    
}
  return (
    <div>
      <OverlayTrigger
          trigger="click"
          key={'bottom'}
          placement={'bottom'}
          overlay={
            <Popover id={`popover-positioned-${'bottom'}`}>
             
              <Popover.Body>
                <div >
             <div className='p-1'><Link onClick={markAsDefault("casesheet")}>Mark As default Casesheet</Link>  </div>
             <div className='p-1'><Link onClick={markAsDefault("FrontdeskForm")}>Mark As default FrontDesk Form</Link>  </div>
             <div className='p-1'><Link onClick={deleteTemp}>Delete </Link></div>
             {
               templete.templeteType !=='TG' &&  <>
               <div className='p-1'><Link onClick={copyLink }> {copyLabel}  </Link></div></>
             }
             <div className='p-1'><Link onClick={edit}>Edit</Link>  </div> 
             </div>
              </Popover.Body>
            </Popover>
          }
          rootClose
        >
          
          <div className="d-flex justify-content-start">
            
          <div className="icon d-flex align-items-center justify-content-center">
		      		<span className="fa fa-user-o"><Icon.ThreeDots size={35}/></span>
              
		      	</div>
                </div>
          
        </OverlayTrigger>
    </div>
  )
}

export default Dropdowncommon