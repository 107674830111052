import React from 'react'
import { useState, useEffect } from 'react'
import { Link, Outlet } from 'react-router-dom'
import * as service from './../../service/ApiService';
import { useSelector } from 'react-redux';
export default function OrgAdminLayout() {
  const userId = useSelector((state) => state.auth.userId);
  const [employeeTabCss, setEmployeeTabCss] = useState('tab-active-css');
  const [doctorTabCss, setDoctorTabCss] = useState();
  const [doctorList, setDoctorList] = useState();
  useEffect(() => {
    const getUsers = async () => {
      const data = await service.getDoctors(userId);
      setDoctorList(data.data);
    };
    getUsers();
  }, []);

  const tabClick = (value) => (e) => {
    if (value === 'employee') {
      setEmployeeTabCss('tab-active-css');
      setDoctorTabCss('');
    } else {
      setEmployeeTabCss('');
      setDoctorTabCss('tab-active-css');
    }
  }

  return (
    <>
      <div className='card mt-4 p-3'>
        <div className='d-flex justify-content-between'>
          <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>Admin Module</div>
          <div>
            .
          </div>
        </div>

        <div className="d-flex justify-content-start p-3">
          <div className={'tab-label ' + employeeTabCss} onClick={tabClick('employee')}>
            <Link to='admin-employee'>
              Employee Setting
            </Link>
          </div>
          <div class="vr"></div>
          <div className={'tab-label ' + doctorTabCss} onClick={tabClick('doctor')}>
            <Link to='admin-doctor'>
              Doctor Setting
            </Link>
          </div>
        </div>

      </div>
      {
        doctorList ? (<Outlet context={[doctorList]} />) : ("")
      }

    </>

  )
}
