import React from 'react'
import { Route, Routes } from 'react-router-dom';
import ManageQuestion from '../PreQuestionModule/ManageQuestion';
import PreQuestionCreater from '../PreQuestionModule/PreQuestionCreater';
import QuestionsTemplateList from '../PreQuestionModule/QuestionsTemplateList';
import DoctorLayout from './DoctorLayout';
import DoctorSetting from './DoctorSetting';
import PaitentCasesheetRouter from '../paitentCasesheet/PaitentCasesheetRouter';
function DoctorRouter() {
  return (
    <Routes>
    <Route element={<DoctorLayout />} >
    <Route path='doctor-setting' element={<DoctorSetting />} />
    <Route path='create-pre-questions' element={<QuestionsTemplateList />} />
    <Route path='manage-templete/:id' element={<ManageQuestion />} />
    <Route path='paitent-doc' element={<PaitentCasesheetRouter />} />
    <Route index element={<DoctorSetting />} />
        </Route>
</Routes>
  )
}

export default DoctorRouter