import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { useOutletContext } from 'react-router-dom';
import * as service from './../../service/SurveyService';
import { useSelector } from 'react-redux';
import FrontDeskSetter from './FrontDeskSetter';

function DoctorSetting() {
  const [templates, setTemplates] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const userId = useSelector((state) => state.auth.userId);
  const [inputFields, setInputFields] = useState([
    { userId: '', templateId: '' , templeteType:''}
  ]);
  useEffect(() => {
    const fetchMyAPI = async () => {
      const template = await service.getTempletes(userId);
      setTemplates(template.data);
      setIsLoading(false);
    }
    fetchMyAPI();
  }, [])

  async function onChangeDoctorSetting(event) {
    inputFields.map((inputField => {
      if (inputField.userId === event.target.id) {
        inputField.templateId = event.target.value;
      }
    }))

  }

  async function saveDoctorSetting(event) {
    const template = await service.saveDoctorSetting(userId, inputFields);
  }

  const [doctorList] = useOutletContext();

  return (
    <div>DoctorSetting

<FrontDeskSetter />



      <Accordion >
        {
          doctorList.map((doctor) =>
            <Accordion.Item eventKey={0} className='pt-3'>
              <Accordion.Header>{doctor.firstName + " " + doctor.lastName}</Accordion.Header>
              <Accordion.Body>
                <div >
                  {
                    inputFields.map((inputField) =>
                      <SettingComponent doctor={doctor} isLoading={isLoading} templates={templates} saveDoctorSetting={saveDoctorSetting} onChangeDoctorSetting={onChangeDoctorSetting} inputField={inputField} />

                    )
                  }
                </div>

              </Accordion.Body>
            </Accordion.Item>
          )
        }




      </Accordion>

    </div>
  )
}

export default DoctorSetting;

const SettingComponent = (props) => {

  const { doctor, isLoading, templates, saveDoctorSetting, inputField, onChangeDoctorSetting } = props;
  //{ templateId: '', tempData: '' }
  //const { doctor, isLoading, templates, saveDoctorSetting, inputField } = props;
  inputField.userId = doctor.p_id;
  return (

    <>
    <div className='p-3'>
    <div class="form-group row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
    <div class="col-sm-10">
    <select class="form-select" aria-label="Default select example" id={doctor.p_id} onChange={onChangeDoctorSetting} name="templateDoctorId">
        <option selected>Open this select menu</option>
        {
          isLoading ? ("Loading") : (
            templates.map((templete) =>
              <>{
                (templete.templeteType === 'TM' &&  templete.groupType=='casesheet')&&
                <>
                  {
                    doctor.caseSheetTempleteId === templete.templeteId ?
                      (<option value={templete.templeteId} selected>{templete.templeteName}</option>) :
                      (<option value={templete.templeteId}>{templete.templeteName}</option>)
                  }
                </>
              }
              </>
            )
          )
        }
        
      </select>
     </div>
  </div>

  
    </div>
    
      
     

      <button type='submit' className="btn btn-primary rounded submit p-2 px-4" onClick={saveDoctorSetting}>Save</button>

    </>

  )

}