import React, { useState, useEffect } from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { useSelector } from 'react-redux';
import * as service from './../../service/ApiService';
import moment from 'moment';
import { toast } from "react-toastify";
import * as constant from '../../Utils/MapConstant';
import * as popUp from '../../component/MessagePopup/MessagePopupComp';

function DoctorTimingScheduler() {
    const userId = useSelector((state) => state.auth.userId);
    const [slotTimingDetails, setSlotTimingDetails] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [daySlotDetails, setDaySlotDetails] = useState();
    const [buttonType, setButtonType] = useState('Monday');
    const [enabledGrouping, setEnabledGrouping] = useState('casesheet');
    const [refresh, setRefresh] = useState(true);
    const [noOfPersonPerSlot, setNoOfPersonPerSlot] = useState(0);
    const [timePerSlot, setTimePerSlot] = useState(0);
    
    const fetchMyAPI = async () => {
        const template = await service.getSlotTimingDetails(userId);
        setLoading(true);
        if (!(template.ErrorCode == undefined)) {
            setErrorMessage(template.ErrorCode);
            if (template.ErrorCode == '2004') {
                let dummyData = {
                    "timePerSlot": 0,
                    "noOfPersonPerSlot": 0,
                    "timingAndSlots": [
                        {
                            "day": "Monday",
                            "slotsDetails": [

                            ]
                        },
                        {
                            "day": "Tuesday",
                            "slotsDetails": []
                        },
                        {
                            "day": "Wednesday",
                            "slotsDetails": []
                        },
                        {
                            "day": "Thursday",
                            "slotsDetails": []
                        },
                        {
                            "day": "Friday",
                            "slotsDetails": []
                        },
                        {
                            "day": "Saturday",
                            "slotsDetails": []
                        },
                        {
                            "day": "Sunday",
                            "slotsDetails": []
                        }
                    ]

                }
                setSlotTimingDetails(dummyData);
                dummyData.timingAndSlots.map(timeSlotData => {

                    if (buttonType == timeSlotData.day) {
                        setDaySlotDetails(timeSlotData);
                    }
                })
            }
        } else {
            setSlotTimingDetails(template);
            template.timingAndSlots.map(timeSlotData => {
            setTimePerSlot(template.timePerSlot);
            setNoOfPersonPerSlot(template.noOfPersonPerSlot)
                if (buttonType == timeSlotData.day) {
                    setDaySlotDetails(timeSlotData);
                }
            })
        }

        return template;
    }
    useEffect(() => {
        const slotDetails = fetchMyAPI();
        setLoading(false)
    }, [buttonType])






    const buttionData = (e) => {
        setButtonType(e);
        slotTimingDetails.timingAndSlots.map(timeSlotData => {

            if (buttonType == timeSlotData.day) {
                setDaySlotDetails(timeSlotData);
            }
        })
    }

    const groupingDunction = (e) => {
        if (e == 'yes') {
            setEnabledGrouping(true);
        } else {
            setEnabledGrouping(false);
        }
    }
    
    const saveTiming = async () => {

        slotTimingDetails.timingAndSlots.map(dayTimeSlots => {
            dayTimeSlots.slotsDetails.map(slotObj => {
                slotObj.slots = [];
            })
        })
        let daytiming = [];

        slotTimingDetails.timingAndSlots.map(timingSlot => {
            let timingData = []
            timingSlot.slotsDetails.map(timin => {
                timingData.push(timin.timing);
            })
            daytiming.push({ "day": timingSlot.day, timing: timingData })

        })
        let requestObj = { doctorId: userId, timePerSlot: timePerSlot, noOfPersonPerSlot: noOfPersonPerSlot, dayTimingAndSlots: daytiming }

        const template = await service.saveSlotTimimg(userId, requestObj);
        if (!(template.ErrorCode != undefined)) {
            popUp.MessagePopupComp('Appointment timing saved successfully',constant.Success);
           }else {
            popUp.MessagePopupComp('Error in Saving Appointment timing',constant.Error);
            }

    }


    return (
        <div className='card p-3 m-2'>
            <div className='card-header'>
                <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>Schedule Timings</div>


            </div>
            {
                loading && (<div className='card-body'>
                    <div className='col-12'>
                        <div className='row'>
                            <div class="form-group row pt-2">
                                <label for="email" class="col-sm-2 col-form-label">Timing Slot Duration</label>
                                <div class="col-sm-10">
                                    {slotTimingDetails != undefined ? (<>
                                        <select class="form-control" name="gender" className={'form-control'} onChange={e=>setTimePerSlot(e.target.value)}>
                                            <option value={'select'}> select Timing Slot Duration</option>
                                            <option value={'05'} selected={slotTimingDetails.timePerSlot == '05'}>05 Min</option>
                                            <option value={'10'} selected={slotTimingDetails.timePerSlot == '10'}>10 Min</option>
                                            <option value={'15'} selected={slotTimingDetails.timePerSlot == '15'}>15 Min</option>
                                            <option value={'20'} selected={slotTimingDetails.timePerSlot == '20'}>20 Min</option>
                                            <option value={'30'} selected={slotTimingDetails.timePerSlot == '30'}>30 Min</option>
                                            <option value={'45'} selected={slotTimingDetails.timePerSlot == '45'}>45 Min</option>
                                            <option value={'60'} selected={slotTimingDetails.timePerSlot == '60'}>1 Hr</option>
                                        </select>
                                    </>) : (
                                        <>
                                            <select class="form-control" name="gender" className={'form-control'} onChange={e=>setTimePerSlot(e.target.value)}>
                                                <option value={'select'}> select Timing Slot Duration</option>
                                                <option value={'05'} >05 Min</option>
                                                <option value={'10'} >10 Min</option>
                                                <option value={'15'} >15 Min</option>
                                                <option value={'20'} >20 Min</option>
                                                <option value={'30'} >30 Min</option>
                                                <option value={'45'} >45 Min</option>
                                                <option value={'60'} >1 Hr</option>
                                            </select>
                                        </>
                                    )}


                                </div>
                            </div>
                            {
                                /*
                     <label for="email" class="col-sm-2 col-form-label">Enable Grouping</label>
                                <div class="col-sm-10 pt-2">
                                    <div className="form-check form-check-inline">
                                        <input className={"form-check-input font-14"}
                                            name="grouping"
                                            type="radio"
                                            value="yes"
                                            id="grouping"
                                            onChange={(e) => groupingDunction(e.target.value)}
                                            checked={(slotTimingDetails.noOfPersonPerSlot < 1) ? "true" : "false"} 
                                        />
                                        <label className="form-check-label font-14" for="inlineRadio1">yes {(slotTimingDetails.noOfPersonPerSlot < 1) ? "true" : "false"} </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                    
                                        <input className={"form-check-input font-14"}
                                            name="grouping"
                                            type="radio"
                                            value="No"
                                            id="grouping"
                                            onChange={(e) => groupingDunction(e.target.value)}
                                            checked={(slotTimingDetails.noOfPersonPerSlot > 1) ? "true" : "false"}
                                       />
                                        <label className="form-check-label font-14" for="inlineRadio2">No {(slotTimingDetails.noOfPersonPerSlot > 1) ? "true" : "false"}</label>
                                    </div>
                                </div>
                                */
                            }





                            <div class="form-group row pt-2">
                                <label for="email" class="col-sm-2 col-form-label">Number of person per group</label>
                                <div class="col-sm-10">
                                    {slotTimingDetails != undefined ? (<>
                                        <select class="form-control" name="gender" className={'form-control'} onChange={e=>setNoOfPersonPerSlot(e.target.value)}>
                                            <option value="select"> select Person per group</option>
                                            <option value={'1'} selected={slotTimingDetails.noOfPersonPerSlot == '1'}>1 per Slot</option>
                                            <option value={'2'} selected={slotTimingDetails.noOfPersonPerSlot == '2'}>2 per Slot</option>
                                            <option value={'3'} selected={slotTimingDetails.noOfPersonPerSlot == '3'}>3 per Slot</option>
                                            <option value={'4'} selected={slotTimingDetails.noOfPersonPerSlot == '4'}>4 per Slot</option>
                                            <option value={'5'} selected={slotTimingDetails.noOfPersonPerSlot == '5'}>5 per Slot</option>
                                            <option value={'6'} selected={slotTimingDetails.noOfPersonPerSlot == '6'}>6 per Slot</option>
                                            <option value={'7'} selected={slotTimingDetails.noOfPersonPerSlot == '7'}>7 per Slot</option>
                                            <option value={'8'} selected={slotTimingDetails.noOfPersonPerSlot == '8'}>8 per Slot</option>
                                            <option value={'9'} selected={slotTimingDetails.noOfPersonPerSlot == '9'}>9 per Slot</option>
                                            <option value={'10'} selected={slotTimingDetails.noOfPersonPerSlot == '10'}>10 per Slot</option>
                                        </select>
                                    </>) : (
                                        <>

                                            <select class="form-control" name="gender" className={'form-control'} onChange={e=>setNoOfPersonPerSlot(e.target.value)}>
                                                <option value="select"> select Person per group</option>
                                                <option value={'1'} >1 per Slot</option>
                                                <option value={'2'} >2 per Slot</option>
                                                <option value={'3'} >3 per Slot</option>
                                                <option value={'4'} >4 per Slot</option>
                                                <option value={'5'} >5 per Slot</option>
                                                <option value={'6'} >6 per Slot</option>
                                                <option value={'7'} >7 per Slot</option>
                                                <option value={'8'} >8 per Slot</option>
                                                <option value={'9'} >9 per Slot</option>
                                                <option value={'10'} >10 per Slot</option>
                                            </select>
                                        </>
                                    )}

                                </div>
                            </div>



                            <div className='row pt-4'>
                                <div className='col-6 '>
                                    <div className='p-4 ms-4'>
                                        <div className='d-flex flex-column justify-content-center'>
                                            <button type='submit' className={buttonType == 'Monday' ? ("btn btn-primary rounded submit px-4") : ("btn-selected btn-primary-selected rounded submit px-4")} name="action" onClick={() => buttionData("Monday")}
                                            >Monday</button>
                                            <button type='submit' className={buttonType == 'Tuesday' ? ("btn btn-primary rounded submit px-4") : ("btn-selected btn-primary-selected rounded submit px-4")} name="action" onClick={() => buttionData("Tuesday")} s
                                            >Tuesday</button>
                                            <button type='submit' className={buttonType == 'Wednesday' ? ("btn btn-primary rounded submit px-4") : ("btn-selected btn-primary-selected rounded submit px-4")} name="action" onClick={() => buttionData("Wednesday")} s
                                            >Wednesday</button>
                                            <button type='submit' className={buttonType == 'Thursday' ? ("btn btn-primary rounded submit px-4") : ("btn-selected btn-primary-selected rounded submit px-4")} name="action" onClick={() => buttionData("Thursday")} s
                                            >Thursday</button>
                                            <button type='submit' className={buttonType == 'Friday' ? ("btn btn-primary rounded submit px-4") : ("btn-selected btn-primary-selected rounded submit px-4")} name="action" onClick={() => buttionData("Friday")} s
                                            >Friday</button>
                                            <button type='submit' className={buttonType == 'Saturday' ? ("btn btn-primary rounded submit px-4") : ("btn-selected btn-primary-selected rounded submit px-4")} name="action" onClick={() => buttionData("Saturday")} s
                                            >Saturday</button>
                                            <button type='submit' className={buttonType == 'Sunday' ? ("btn btn-primary rounded submit px-4") : ("btn-selected btn-primary-selected rounded submit px-4")} name="action" onClick={() => buttionData("Sunday")} s
                                            >Sunday</button>


                                        </div>
                                    </div>
                                </div>

                                <div className='col-6'>
                                    <TimeSlotLoader daySlotDetails={daySlotDetails} setDaySlotDetails={setDaySlotDetails} slotTimingDetails={slotTimingDetails} buttonType={buttonType} />

                                </div>
                                <div className='card-footer'>
                                    <div className='d-flex justify-content-end'>
                                        <div >
                                            <button type='submit' className={"btn btn-primary rounded"} onClick={saveTiming}>Save Schedule Timings </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>)
            }


        </div>
    )
}
const TimeSlotLoader = (props) => {

    const { daySlotDetails, setDaySlotDetails, slotTimingDetails, buttonType } = props;
    const [counter, setCounter] = useState();
    useEffect(() => {

    }, [counter,daySlotDetails])

    const addTiming = () => {
        var timing = { timing: { startTiming: "", endTiming: "", id: daySlotDetails.slotsDetails.length } }
        daySlotDetails.slotsDetails.push(timing);
        setDaySlotDetails(daySlotDetails);
        slotTimingDetails.timingAndSlots.map((slotUpdate, ind) => {
            if (slotUpdate.day == buttonType) {
                slotTimingDetails.timingAndSlots[ind] = daySlotDetails;
            }
        })
        setCounter(Math.random()+daySlotDetails.slotsDetails.length);
         }

    const remove = (e) => {
        daySlotDetails.slotsDetails.splice(e, 1);
        slotTimingDetails.timingAndSlots.map((slotUpdate, ind) => {
            if (slotUpdate.day == buttonType) {
                slotTimingDetails.timingAndSlots[ind] = daySlotDetails;
            }
        })
        setDaySlotDetails(daySlotDetails);
        setCounter(Math.random()+daySlotDetails.slotsDetails.length);
       }


    const getTime = (time) => {
        if (time != "") {
            var date = new Date();
            var convertedTime = moment(time, 'hh:mm A').format('HH:mm')
            date.setHours(convertedTime.split(":")[0])
            date.setMinutes(convertedTime.split(":")[1])
            return date;
        } else {
            return ""
        }

    }

    const setValue = (e, index, functionType) => {
        slotTimingDetails.timingAndSlots.map((slotUpdate, ind) => {
            if (buttonType == slotUpdate.day) {

                slotUpdate.slotsDetails.map(slotTime => {
                    if (slotTime.timing.id == index) {
                        let time = new Date(e).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                        if (time.split(":")[0].length == 1) {
                            time = "0" + time;
                        }
                        if (functionType == 'from') {
                            slotTime.timing.startTiming = time;

                        } else if (functionType == 'to') {
                            slotTime.timing.endTiming = time;
                            ;
                        }
                    }

                })
            }
        })
       }


    return <div className='row'>
        <div className='d-flex justify-content-end'>
            <div>
                <button type='submit' className={"btn btn-primary rounded"} onClick={addTiming} >Add Time  </button>
            </div>
        </div>

        {
            daySlotDetails.slotsDetails.map((slot, ind) => (
                <>
                    {

                        <div className='d-flex justify-content-between'>
                            {JSON.stringify(slot.timing.id)}
                            <div className='p-2'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="From"
                                        value={dayjs(getTime(slot.timing.startTiming))}
                                        // defaultValue={dayjs(new Date()).format("HH:mm:00")}
                                        formatDate={(date) => moment(date).format('DD-MM-YYYY')}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                        onChange={(newValue) => setValue(newValue, ind, "from")}
                                    />
                                </LocalizationProvider>

                            </div>
                            <div className='p-2'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="To"
                                        value={dayjs(getTime(slot.timing.endTiming))}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                        onChange={(newValue) => setValue(newValue, ind, "to")}
                                    />
                                </LocalizationProvider>

                            </div>

                            <div className='pt-1'>
                                <button type='submit' className={"btn btn-primary rounded "} onClick={e => remove(ind)}>remove</button>

                            </div>
                        </div>
                    }
                </>
            )
            )


        }

        {


        }


    </div>
}

export default DoctorTimingScheduler