import React, { useState, useEffect } from 'react'
import QuestionManager from '../../component/QuestionsManager/QuestionManager'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import * as service from '../../service/SurveyService';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MessagePopupComp from '../../component/MessagePopup/MessagePopupComp';
import * as Apiservice from './../../service/ApiService';
import * as Icon from 'react-bootstrap-icons';
import FrontDeskSetter from '../orgAdmin/FrontDeskSetter';
import CasesheetSetter from '../orgAdmin/CasesheetSetter';
import DoctorTimingScheduler from '../../component/TimingsScheduler/DoctorTimingScheduler';
import * as constant from '../../Utils/MapConstant';
import * as popUp from '../../component/MessagePopup/MessagePopupComp';

function DoctorSetting() {
  const userId = useSelector((state) => state.auth.userId);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [userLoading, setUserLoading] = useState(false);
  const [buttonType, setButtonType] = useState('casesheet');


  const initialValues = {
    mobileNumber: '',
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    age: '',
    gender: '',
    roleName: ''
  }
  const createUser = Yup.object().shape({

    mobileNumber: Yup.string()
      .required('Mobile is required')
      .min(7, 'Minimum 3 symbols')
      .max(11, 'Maximum 11 symbols'),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    firstName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(15, 'Maximum 50 symbols')
      .required('First Name is required'),
    middleName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(15, 'Maximum 50 symbols'),
    lastName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .required('Last Name is required')
      .max(15, 'Maximum 50 symbols'),
    age: Yup.string()
      .min(1, 'Minimum 1 symbols')
      .max(3, 'Maximum 3 symbols')
      .required('Age is required'),
    gender: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(30, 'Maximum 30 symbols'),
    roleName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(30, 'Maximum 30 symbols')

  })


  const formik = useFormik({
    initialValues,
    validationSchema: createUser,

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true);
        setUserLoading(true);
        let expMsg = "";
        const createData = await Apiservice.createUser(userId, values);
  if (!(createData.ErrorCode != undefined)) {
    popUp.MessagePopupComp('User Created with Employee ID - ' + createData.p_id,constant.Success);
          formik.resetForm();
        } else {
          popUp.MessagePopupComp(createData.message,constant.Error);
         }
        setLoading(false)
        setUserLoading(false);
      } catch (error) {
      }
    }
  })

  useEffect(() => {
    const fetchMyAPI = async () => {
      setLoading(true);
      const data = await Apiservice.getUsers(userId);
      setUserData(data);
      setLoading(false);
    }
    fetchMyAPI();
  }, [userLoading])

  function resetForm() {
    formik.resetForm();
  }

  async function deleteUser(deleteUser) {
    setUserLoading(true);
    const data = await service.deleteUser(userId, deleteUser);
    setUserLoading(false);
  }
const buttionData =(data)=>{
  setButtonType(data);
}
  return (
    <>
     <div className='pt-2'>
    <div className='card mt-4 p-2'>
              <div className='d-flex justify-content-center'>
                <button type='submit' className={buttonType == 'casesheet' ? ("btn btn-primary rounded submit px-4") : ("btn-selected btn-primary-selected rounded submit px-4") } name="action" onClick={()=>buttionData("casesheet")}
                >Casesheet Setting</button>
                <button type='submit' className={buttonType == 'user-management' ? ("btn btn-primary rounded submit px-4") : ("btn-selected btn-primary-selected rounded submit px-4") }  name="action" onClick={()=>buttionData("user-management")}s
                >User Setting</button>
                 <button type='submit' className={buttonType == 'schedule-timings' ? ("btn btn-primary rounded submit px-4") : ("btn-selected btn-primary-selected rounded submit px-4") }  name="action" onClick={()=>buttionData("schedule-timings")}s
                >Schedule Timings</button>

              </div>
            </div>
            </div>
      {
        buttonType == 'casesheet' && (
          <div className=' mt-4 p-2'>
            
            <FrontDeskSetter />
            <CasesheetSetter doctor={userId}/>
          </div>
        )

      }
       {
        buttonType == 'schedule-timings' && (
           <DoctorTimingScheduler/>
         )

      }

      {
        buttonType == 'user-management' && (
          <div className='card mt-4 p-2'>
            
            <div>


              <form className="login-form" onSubmit={formik.handleSubmit}>
                <div className='card mt-2 p-2'>
                  <div className='card-header'>
                    <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'> Create User</div>


                  </div>

                  <div className='row'>
                    <div className='col-8'>
                      <div className='row'>
                        <div class="form-group row pt-2">
                          <label for="email" class="col-sm-2 col-form-label">Email</label>
                          <div class="col-sm-10">
                            <input type="text" class="form-control" id="email" value="" placeholder='Email' name="email"
                              {...formik.getFieldProps('email')}
                              className={clsx(
                                'form-control',
                                { 'is-invalid': formik.touched.email && formik.errors.email },
                                {
                                  'is-valid': formik.touched.email && !formik.errors.email,
                                }
                              )} />
                          </div>
                        </div>
                        <div class="form-group row pt-2">
                          <label for="fname" class="col-sm-2 col-form-label">First Name</label>
                          <div class="col-sm-10">
                            <input type="text" class="form-control" id="firstName" placeholder="First Name"
                              name="firstName"
                              {...formik.getFieldProps('firstName')}
                              className={clsx(
                                'form-control',
                                { 'is-invalid': formik.touched.firstName && formik.errors.firstName },
                                {
                                  'is-valid': formik.touched.firstName && !formik.errors.firstName,
                                }
                              )}
                            />
                          </div>
                        </div>
                        <div class="form-group row pt-2">
                          <label for="mName" class="col-sm-2 col-form-label">Middle Name</label>
                          <div class="col-sm-10">
                            <input type="text" class="form-control" id="middleName" placeholder="Middle Name" name="middleName"
                              {...formik.getFieldProps('middleName')}
                              className={clsx(
                                'form-control',
                                { 'is-invalid': formik.touched.middleName && formik.errors.middleName },
                                {
                                  'is-valid': formik.touched.middleName && !formik.errors.middleName,
                                }
                              )} />
                          </div>
                        </div>
                        <div class="form-group row pt-2">
                          <label for="lname" class="col-sm-2 col-form-label">Last Name</label>
                          <div class="col-sm-10">
                            <input type="text" class="form-control" id="lastName" placeholder="Last Name" name="lastName"
                              {...formik.getFieldProps('lastName')}
                              className={clsx(
                                'form-control',
                                { 'is-invalid': formik.touched.lastName && formik.errors.lastName },
                                {
                                  'is-valid': formik.touched.lastName && !formik.errors.lastName,
                                }
                              )} />
                          </div>
                        </div>
                        <div class="form-group row pt-2">
                          <label for="lname" class="col-sm-2 col-form-label">Mobile</label>
                          <div class="col-sm-10">
                            <input type="text" class="form-control" id="mobileNumber" placeholder="Mobile Number" name="mobileNumber"
                              {...formik.getFieldProps('mobileNumber')}
                              className={clsx(
                                'form-control',
                                { 'is-invalid': formik.touched.mobileNumber && formik.errors.mobileNumber },
                                {
                                  'is-valid': formik.touched.mobileNumber && !formik.errors.mobileNumber,
                                }
                              )} />
                          </div>
                        </div>
                        <div class="form-group row pt-2">
                          <label for="role" class="col-sm-2 col-form-label">Role</label>
                          <div class="col-sm-10">
                            <select class="form-control" name="roleName"
                              {...formik.getFieldProps('roleName')}
                              className={clsx(
                                'form-control',
                                { 'is-invalid': formik.touched.roleName && formik.errors.roleName },
                                {
                                  'is-valid': formik.touched.roleName && !formik.errors.roleName,
                                }
                              )}
                            >
                              <option value="">select Role</option>
                              <option value="op-rec">Receptionist</option>
                              <option value="re-operator">Casesheet conductor</option>
                            </select>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className='col-4'>
                      <div class="form-group row pt-2">
                        <label for="staticEmail" class="col-sm-4 col-form-label">Age</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" id="age" placeholder='Age' name="age"
                            {...formik.getFieldProps('age')}
                            className={clsx(
                              'form-control',
                              { 'is-invalid': formik.touched.age && formik.errors.age },
                              {
                                'is-valid': formik.touched.age && !formik.errors.age,
                              }
                            )} />
                        </div>
                      </div>
                      <div class="form-group row pt-2">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Gender</label>
                        <div class="col-sm-8">
                          <select class="form-control" name="gender"
                            {...formik.getFieldProps('gender')}
                            className={clsx(
                              'form-control',
                              { 'is-invalid': formik.touched.gender && formik.errors.gender },
                              {
                                'is-valid': formik.touched.gender && !formik.errors.gender,
                              }
                            )}>
                            <option> select Gender</option>
                            <option>Male</option>
                            <option>Female</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <button type='submit' className="btn btn-primary rounded submit p-2 px-4" onClick={resetForm}>
                        reset
                      </button>
                    </div>
                    <div>
                      <button type='submit' className="btn btn-primary rounded submit p-2 px-4" disabled={formik.isSubmitting || !formik.isValid}>
                        {!loading && <span className='indicator-label'>Save</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}

                      </button>
                    </div>
                  </div>
                </div>

              </form>
              <div className='card mt-2 p-4'>
                <div className='card-header'>
                  <div className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'> Users </div>


                </div>

                {
                  !userLoading && userData == undefined ? (
                    <div className="row justify-content-center">No user Created</div>) : (<>
                      <table class="table p-4">
                        <thead >
                          <tr>
                            <th scope="col">Emp Id</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Role</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            userData.map((userData) =>
                              <>
                                {userData.roleName != 'doctor' &&
                                  <tr>
                                    <th>{userData.p_id}</th>
                                    <th>{userData.lastName + " " + userData.firstName}</th>
                                    <th>{userData.email}</th>
                                    <th>{userData.mobileNumber}</th>
                                    <th>{userData.roleDesc}</th>
                                    <th>{userData.createdDate}</th>
                                    <th>
                                      <button className='noStyle' onClick={() => deleteUser(userData.p_id)} > <Icon.Trash3Fill /></button>
                                    </th>


                                  </tr>

                                }
                              </>

                            )
                          }

                        </tbody>
                      </table>
                    </>)
                }


              </div>
            </div>
          </div>
        )

      }

    </>
  )
}

export default DoctorSetting