import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL
const SAVE_TEMPLATE = `${API_URL}/savetemplate`;
const CREATE_TEMPLATE = `${API_URL}/create-templete`;
const UPDATE_TEMPLATE_META = `${API_URL}/update-templete-meta`;
const GET_TEMPLATES = `${API_URL}/templetes`;
const GET_GROUP_TEMPLATES = `${API_URL}/group-templetes`;
const GET_TEMPLATE_ID = `${API_URL}/templete`;
const GET_TEMPLATE_URL = `${API_URL}/templete-by-url`;
const DELETE_TEMPLATE_ID = `${API_URL}/delete-templete`;
const SURVEY_ANSWER_SAVE = `${API_URL}/submit-survey`;
const SET_DEFAULT_TEMP = `${API_URL}/set-default-templete`;
const SET_DEFAULT_TEMP_FOR_DOC = `${API_URL}/save-hospital-setting`;
const SET_DEFAULT_CASESHEET_TEMP_FOR_DOC = `${API_URL}/save-doctor-setting`;

const DELETE_USER = `${API_URL}/delete-user`;
const GET_APPOINTMENT_STATS = `${API_URL}/get-appointment-stats`;
const GET_APPOINTMENT_STATS_FOR_DOCTOR = `${API_URL}/get-appointment-stats-doctor`;
export function saveTemplates(userId, templateDate) {
  const config = {
    headers: {
      'userId': "userId"
    },
    //body:{templateDate}
  };
  return axios.post(SAVE_TEMPLATE, templateDate, config)
}

export function createTemplete(userId, templateDate) {
  const config = {
    headers: {
      'userId': userId
    },
    //body:{templateDate}
  };
  return axios.post(CREATE_TEMPLATE, templateDate, config).then((res) => {
    return res
  })
    .catch((error) => {
      return error.response.data;
    })
}

export function updateTemplete(userId, templateDate) {
  const config = {
    headers: {
      'userId': "userId"
    },
    //body:{templateDate}
  };
  return axios.post(UPDATE_TEMPLATE_META, templateDate, config).then((res) => {
    return res.data
  })
    .catch((error) => {
      return error.response.data;
    })
}

export function getTempletes(userId) {
  //let COMP_GET_USER_DETAILS_MOBILE_NUMBER = GET_USER_DETAILS_MOBILE_NUMBER.concat("/" + mobileNumber);
  const config = {
    headers: {
      'userId': userId
    }
  };
  return axios.get(GET_TEMPLATES,
    config
  )
}

export function getGroupTempletes(userId) {
  //let COMP_GET_USER_DETAILS_MOBILE_NUMBER = GET_USER_DETAILS_MOBILE_NUMBER.concat("/" + mobileNumber);
  const config = {
    headers: {
      'userId': userId
    }
  };
  return axios.get(GET_GROUP_TEMPLATES,
    config
  )
}

export function getTempleteById(userId, templateId) {
  let templateIdUrl = GET_TEMPLATE_ID.concat("/" + templateId);
  const config = {
    headers: {
      'userId': userId
    }
  };
  return axios.get(templateIdUrl,
    config
  )
}

export function getTempleteByUrl(userId, templateId,paitentId) {
  let templateIdUrl = GET_TEMPLATE_URL.concat("/" + templateId +"/"+paitentId);
  const config = {
    headers: {
      'userId': userId
    }
  };
  return axios.get(templateIdUrl,
    config
  )
}




export function deleteTemplete(userId, templateId) {
  let templateIdUrl = DELETE_TEMPLATE_ID.concat("/" + templateId);
  const config = {
    headers: {
      'userId': userId
    }
  };
  return axios.delete(templateIdUrl,
    config
  ).then((res) => {
    return res
  })
    .catch((error) => {
      return error.response.data;
    })
}

export function getAppointmentStats(userId, date) {
  let templateIdUrl = GET_APPOINTMENT_STATS;
  const config = {
    headers: {
      'userId': userId,
      'user_date': date
    }
  };
  return axios.get(templateIdUrl,
    config
  ).then((res) => {
    return res
  })
    .catch((error) => {
      return error.response.data;
    })
}

export function getAppointmentStatsFOrDoctor(userId, date, doctor) {
  let templateIdUrl = GET_APPOINTMENT_STATS_FOR_DOCTOR.concat("/" + doctor);

  const config = {
    headers: {
      'userId': userId,
      'user_date': date
    }
  };
  return axios.get(templateIdUrl,
    config
  ).then((res) => {
    return res
  })
    .catch((error) => {
      return error.response.data;
    })
}

export function postSurveyAnswer(userId, surveyAnswerData) {
  const config = {
    headers: {
      'userId': userId
    },
  };
  return axios.post(SURVEY_ANSWER_SAVE, surveyAnswerData, config)
}

export function setDefaultCaseSheet(userId, templeteId, value) {
  let templateIdUrl = SET_DEFAULT_TEMP.concat("/" + templeteId) + "?type=" + value;
  const config = {
    headers: {
      'userId': userId
    },
  };
  return axios.get(templateIdUrl, config)
}

export function saveDoctorSetting(userId, data) {
  let templateIdUrl = SET_DEFAULT_TEMP_FOR_DOC;//.concat("/" + templeteId)+"?type="+value;
  const config = {
    headers: {
      'userId': userId
    },
  };
  return axios.post(templateIdUrl, data, config).then((res) => {
    return res
  })
    .catch((error) => {
      return error.response.data;
    })
}

export function saveFrontDeskSetting(userId, data) {
  let templateIdUrl = SET_DEFAULT_TEMP_FOR_DOC;//.concat("/" + templeteId)+"?type="+value;
  const config = {
    headers: {
      'userId': userId
    },
  };
  return axios.post(templateIdUrl, data, config).then((res) => {
    return res
  })
    .catch((error) => {
      return error.response.data;
    })
}

export function deleteUser(userId, deleteUser) {
  let templateIdUrl = DELETE_USER.concat("/" + deleteUser);
  const config = {
    headers: {
      'userId': userId
    }
  };
  return axios.delete(templateIdUrl,
    config
  ).then((res) => {
    return res
  })
    .catch((error) => {
      return error.response.data;
    })
}