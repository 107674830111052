import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Accordion.scss';
import Accordion from 'react-bootstrap/Accordion';
import MenuItem from '../sidebar/MenuItem';
function AccordionMenu(props) {
    
    const { title, content } =props;
   
    const [isActive, setIsActive] = useState(false);
   
  return (

   
            <div className="accordion-item">
    <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
      <div>{title}</div>
      <div>{isActive ? '-' : '+'}</div>
    </div>
    {isActive && <div className="accordion-content">{content.map((menuItem)=>
    <>
    <div>
      <MenuItem uri={menuItem.uri} displayName={menuItem.DisplayName}/>
    
    </div>
    </>
)}</div>}
  </div>

    
    
  )
  
}

export default AccordionMenu