import React from 'react'
import { Button } from 'react-bootstrap'
import { saveAuth} from './../../../reducer/authReducer';
import {  useDispatch } from 'react-redux';
function Logout() {
    const dispatch = useDispatch()
    function logOut(){
        dispatch(saveAuth(undefined));
    }
  return (
    <div><Button onClick={logOut}>Logout</Button></div>
  )
}

export default Logout